import { useCallback, useEffect, useRef, useState } from 'react';
import {
  RelatedParty,
  RelatedPartyCreateDto,
  RelatedPartyUpdateDto,
  RelatedPartyUpdateEntityTypeDto,
  RelatedPartyEntityType,
  useLazyGetJobPartiesQuery,
  useCreateRelatedPartyMutation,
  useUpdateRelatedPartyMutation,
  useUpdateRelatedPartyEntityTypeMutation,
  useDeleteRelatedPartyMutation,
  useResetRelatedPartyMutation,
} from 'api/related-parties';
import { SubmissionRelatedPartyStatus } from '../../../../../api';
import { getDistrict, getMunicipality, getParish } from '../../../../../utils';

const finalStatuses = [SubmissionRelatedPartyStatus.COMPLETED, SubmissionRelatedPartyStatus.ERROR];

export const useRelatedParties = (jobId: string, isInited: boolean) => {
  const [buyers, setBuyers] = useState<RelatedParty[]>([]);
  const [sellers, setSellers] = useState<RelatedParty[]>([]);

  const [buyersSearch, setBuyersSearch] = useState('');
  const [sellersSearch, setSellersSearch] = useState('');

  const pollTimerRef = useRef<NodeJS.Timeout | null>(null);

  const [fetchJobParties] = useLazyGetJobPartiesQuery();
  const [create] = useCreateRelatedPartyMutation();
  const [update] = useUpdateRelatedPartyMutation();
  const [updateEntityType] = useUpdateRelatedPartyEntityTypeMutation();
  const [deleteParty] = useDeleteRelatedPartyMutation();
  const [reset] = useResetRelatedPartyMutation();

  const isPartyStillProcessing = (p: RelatedParty): boolean => {
    if (!p.submission) return false;
    if (!finalStatuses.includes(p.submission.status)) {
      return true;
    }
    return (p.nestedRelatedParties ?? []).some(n => {
      if (!n.submission) return false;
      return !finalStatuses.includes(n.submission.status);
    });
  };

  const loadParties = useCallback(async () => {
    if (!isInited) return;

    if (pollTimerRef.current) {
      clearTimeout(pollTimerRef.current);
      pollTimerRef.current = null;
    }

    try {
      const [buyersResult, sellersResult] = await Promise.all([
        fetchJobParties({
          jobId,
          filter: {
            entityType: RelatedPartyEntityType.BUYER,
            search: buyersSearch,
          },
        }).unwrap(),
        fetchJobParties({
          jobId,
          filter: {
            entityType: RelatedPartyEntityType.SELLER,
            search: sellersSearch,
          },
        }).unwrap(),
      ]);

      setBuyers(buyersResult ?? []);
      setSellers(sellersResult ?? []);

      const hasPendingBuyer = (buyersResult ?? []).some(isPartyStillProcessing);
      const hasPendingSeller = (sellersResult ?? []).some(isPartyStillProcessing);

      if (hasPendingBuyer || hasPendingSeller) {
        pollTimerRef.current = setTimeout(() => {
          loadParties();
        }, 3000);
      }
    } catch (e) {
      console.error(e);
    }
  }, [isInited, jobId, buyersSearch, sellersSearch, fetchJobParties]);

  useEffect(() => {
    loadParties();
    return () => {
      if (pollTimerRef.current) {
        clearTimeout(pollTimerRef.current);
        pollTimerRef.current = null;
      }
    };
  }, [loadParties]);

  const onCreate = useCallback(
    async (dto: RelatedPartyCreateDto) => {
      await create({
        ...dto,
        corpAddressDistrict: getDistrict(dto.corpAddressDistrict),
        corpAddressMunicipality: getMunicipality(dto.corpAddressMunicipality),
        corpAddressParish: getParish(dto.corpAddressParish),
      }).unwrap();
      loadParties();
    },
    [create, loadParties],
  );

  const onUpdate = useCallback(
    async (dto: RelatedPartyUpdateDto) => {
      await update({
        ...dto,
        corpAddressDistrict: getDistrict(dto.corpAddressDistrict),
        corpAddressMunicipality: getMunicipality(dto.corpAddressMunicipality),
        corpAddressParish: getParish(dto.corpAddressParish),
      }).unwrap();
      loadParties();
    },
    [update, loadParties],
  );

  const onDeleteItems = useCallback(
    async (ids: string[]) => {
      await Promise.all(ids.map(id => deleteParty({ id }).unwrap()));
      loadParties();
    },
    [deleteParty, loadParties],
  );

  const onUpdateEntityTypes = useCallback(
    async (dtos: RelatedPartyUpdateEntityTypeDto[]) => {
      await Promise.all(dtos.map(dto => updateEntityType(dto).unwrap()));
      loadParties();
    },
    [updateEntityType, loadParties],
  );

  const onResetToDefault = useCallback(
    async (entityType: RelatedPartyEntityType) => {
      await reset({ jobId, entityType }).unwrap();
      loadParties();
    },
    [reset, loadParties, jobId],
  );

  const onBuyerSearchChange = useCallback((value: string) => setBuyersSearch(value), []);
  const onSellerSearchChange = useCallback((value: string) => setSellersSearch(value), []);

  return {
    buyers,
    sellers,
    onBuyerSearchChange,
    onSellerSearchChange,
    onDeleteItems,
    onResetToDefault,
    onCreate,
    onUpdate,
    onUpdateEntityTypes,
    loadParties,
  };
};
