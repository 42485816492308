import { createBreakpoints } from '@chakra-ui/theme-tools';

export const APP_BREAKPOINTS = {
  sm: '320px',
  '2sm': '380px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  xxl: '1400px',
  '2xl': '1600px',
  '3xl': '1920px',
};

export const breakpoints = createBreakpoints(APP_BREAKPOINTS);
