import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { initCreateRelatedPartyData, useRelatedPartyForm } from './useRelatedPartyForm';
import {
  RelatedParty,
  RelatedPartyEntityType,
  RelatedPartyRelationType,
  RelatedPartyUpdateDto,
  RelatedPartyUpdateEntityTypeDto,
} from 'api/related-parties';
import { MutateRelatedPartyForm } from './MutateRelatedPartyForm';
import { AppButton, AppButtonColorScheme, AppButtonType } from '../../../../../../libs/ui/atoms/src/lib/appButton';
import { Vector } from '../../../../../../libs/ui/atoms/src';
import { useAppToast } from '../../../../../../libs/ui/hooks';

export interface EditRelatedPartyModalProps {
  isOpen: boolean;
  relatedParty: RelatedParty;
  parentParty?: RelatedParty;
  onSubmit: (data: RelatedPartyUpdateDto) => Promise<void>;
  onUpdateEntityType: (data: RelatedPartyUpdateEntityTypeDto) => Promise<void>;
  onClose: () => void;
}

export const EditRelatedPartyModal = ({
  isOpen,
  relatedParty,
  parentParty,
  onSubmit,
  onUpdateEntityType,
  onClose,
}: EditRelatedPartyModalProps) => {
  const { t } = useTranslation();
  const { showErrorCustom, showValidationError } = useAppToast();

  const { form, dispatch, validate } = useRelatedPartyForm();

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isLoadingUpdateEntityType, setIsLoadingUpdateEntityType] = useState(false);

  const close = useCallback(() => {
    dispatch({ type: 'setInitialState', value: { ...initCreateRelatedPartyData } });
    onClose();
  }, [onClose, dispatch]);

  useEffect(() => {
    if (!relatedParty) return;

    dispatch({
      type: 'setInitialState',
      value: {
        relationType: relatedParty.relationType,
        name: relatedParty.name,
        email: relatedParty.email,
        nif: relatedParty.nif,
        address: relatedParty.address,
        phone: relatedParty.phone,
        civilStatus: relatedParty.civilStatus,
        marriageRegime: relatedParty.marriageRegime,
        age: relatedParty.age,
        marriedTo: relatedParty.marriedTo,
        nacionalidade: relatedParty.nacionalidade,
        isBornInPortugal: !relatedParty.placeOfBirth,
        placeOfBirthFreguesia: relatedParty.placeOfBirthFreguesia,
        placeOfBirthConcelho: relatedParty.placeOfBirthConcelho,
        placeOfBirth: relatedParty.placeOfBirth,
        idDocumentType: relatedParty.idDocumentType,
        idDocumentNumber: relatedParty.idDocumentNumber,
        idDocumentIssuanceAuthority: relatedParty.idDocumentIssuanceAuthority,
        idDocumentIssueDate: relatedParty.idDocumentIssueDate ? new Date(relatedParty.idDocumentIssueDate) : undefined,
        idDocumentExpiryDate: relatedParty.idDocumentExpiryDate
          ? new Date(relatedParty.idDocumentExpiryDate)
          : undefined,
        corpRepAuthority: relatedParty.corpRepAuthority,
        corpAddress: relatedParty.corpAddress,
        corpAddressParish: relatedParty.corpAddressParish,
        corpAddressPostalCode: relatedParty.corpAddressPostalCode,
        corpAddressMunicipality: relatedParty.corpAddressMunicipality,
        corpAddressDistrict: relatedParty.corpAddressDistrict,
        corpCapital: relatedParty.corpCapital,
        isFiador: relatedParty.isFiador,
        isAutorizante: relatedParty.isAutorizante,
      },
    });
  }, [dispatch, relatedParty]);

  const onSubmitClick = useCallback(async () => {
    if (!relatedParty) return;

    const validationMessages = validate();
    if (validationMessages.length > 0) {
      showValidationError(validationMessages.map(i => t(i)).join(', \n'));
      return;
    }

    try {
      setIsLoadingSubmit(true);
      await onSubmit({
        ...(form as RelatedPartyUpdateDto),
        id: relatedParty.id,
      });

      close();
    } catch (error) {
      showErrorCustom(
        t('relatedParties.unableToUpdateRelatedParty'),
        ((error as FetchBaseQueryError).data as Error)?.message,
      );
    } finally {
      setIsLoadingSubmit(false);
    }
  }, [validate, showValidationError, showErrorCustom, t, onSubmit, form, relatedParty, close]);

  const onUpdateEntityTypeClick = useCallback(async () => {
    if (!relatedParty) return;

    try {
      const newEntityType =
        relatedParty?.entityType === RelatedPartyEntityType.BUYER
          ? RelatedPartyEntityType.SELLER
          : RelatedPartyEntityType.BUYER;

      setIsLoadingUpdateEntityType(true);
      await onUpdateEntityType({
        id: relatedParty.id,
        entityType: newEntityType,
      });

      close();
    } catch (error) {
      showErrorCustom(
        t('relatedParties.unableToUpdateRelatedParty'),
        ((error as FetchBaseQueryError).data as Error)?.message,
      );
    } finally {
      setIsLoadingUpdateEntityType(false);
    }
  }, [showErrorCustom, t, onUpdateEntityType, relatedParty, close]);

  const textColor = useColorModeValue('navy.750', 'white');

  return (
    <Modal size="xl" isOpen={isOpen} onClose={close} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent color={textColor} maxH={'calc(100% - 0rem)'} maxW={'900px'} p="24px" borderRadius="16px">
        <ModalHeader p="0" fontSize="24px">
          {t(
            relatedParty?.entityType === RelatedPartyEntityType.BUYER
              ? 'relatedParties.updateBuyer'
              : 'relatedParties.updateSeller',
          )}
        </ModalHeader>
        <ModalCloseButton right="24px" top="none" />
        <ModalBody p="24px 1px">
          <MutateRelatedPartyForm
            entityType={relatedParty?.entityType}
            isHideRelationRadioButton
            isShowCorpAuthority={parentParty?.relationType === RelatedPartyRelationType.COMPANY}
            form={form}
            dispatch={dispatch}
          />
        </ModalBody>

        <ModalFooter justifyContent="center" gap="24px" p="0">
          {!relatedParty?.relatedPartyId && (
            <AppButton
              leftIcon={<Vector color={textColor} w="15px" h="15px" />}
              isLoading={isLoadingUpdateEntityType}
              buttonType={AppButtonType.PRIMARY_MAIN}
              colorSchemes={[AppButtonColorScheme.INFO]}
              onClick={onUpdateEntityTypeClick}
              title={
                relatedParty?.entityType === RelatedPartyEntityType.BUYER
                  ? t('relatedParties.changeToSellers')
                  : t('relatedParties.changeToBuyers')
              }
            />
          )}
          <AppButton
            isLoading={isLoadingSubmit}
            buttonType={AppButtonType.PRIMARY_MAIN}
            onClick={onSubmitClick}
            title={t('submit')}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
