import { Job, JobDocument } from 'api';
import { useMemo } from 'react';
import { FieldState } from './widgets/JobDetailsWidget/useJobForm';
import { DocumentCheckStatus } from './widgets/JobDetailsWidget/JobDetailsWidget';
import { DocumentsValidationErrorTypeEnum } from './widgets/JobDocumentsWidget/DocumentValidationModals/ModalValidationError';

const useJobProps = (
  job: Job,
  hideExtraData: boolean,
  jobName: FieldState,
  onJobNameChanged: (jobName: string) => void,
  isGenerateReportPT: boolean,
  onGenerateReportPTToggle: (isGenerateReportPT: boolean) => void,
  isGenerateReportEN: boolean,
  onGenerateReportENToggle: (isGenerateReportEN: boolean) => void,
  uploadedDocs: JobDocument[],
  documentsValidationError: DocumentsValidationErrorTypeEnum | null,
  onUploadedDocs: (uploadedDocs: JobDocument[]) => void,
  checkRequiredDocumentsProvided: (documents: JobDocument[]) => DocumentCheckStatus,
  onDocumentsValidationError: React.Dispatch<React.SetStateAction<DocumentsValidationErrorTypeEnum | null>>,
  isShowUserSelectedUnsupportedTypeModal: boolean,
  onShowUserSelectedUnsupportedTypeModal: (isShowUserSelectedUnsupportedTypeModal: boolean) => void,
  addDocumentsToPolling: (documents: JobDocument[]) => void,
  isAccordionExpanded: boolean,
  onAccordionExpanded: (isAccordionExpanded: boolean) => void,
  isProblemsNavigationModalToggle: boolean,
  onProblemsNavigationModalToggle: (isProblemsNavigationModalToggle: boolean) => void,
  documentValidationErrorMessage?: string,
  onResetDocumentValidationErrorMessage?: () => void,
  isErrorModalShown?: boolean,
  setIsErrorModalShown?: React.Dispatch<React.SetStateAction<boolean>>,
  certidaoComercialPreFilledAccessCode?: string,
  onResetCertidaoComercialPreFilledAccessCode?: () => void,
) => {
  const JobMainInfoProps = useMemo(
    () => ({
      job,
      hideExtraData,
      jobName,
      onNameUpdate: onJobNameChanged,
    }),
    [job, hideExtraData, jobName, onJobNameChanged],
  );

  const JobDocumentsStepProps = useMemo(
    () => ({
      job,
      documentsValidationError,
      onDocumentsValidationError,
      isShowUserSelectedUnsupportedTypeModal,
      onShowUserSelectedUnsupportedTypeModal,
      addDocumentsToPolling,
      uploadedDocs,
      onUploadedDocs,
      documentValidationErrorMessage,
      onResetDocumentValidationErrorMessage,
      isErrorModalShown,
      setIsErrorModalShown,
      certidaoComercialPreFilledAccessCode,
      onResetCertidaoComercialPreFilledAccessCode,
    }),
    [
      job,
      documentsValidationError,
      onDocumentsValidationError,
      isShowUserSelectedUnsupportedTypeModal,
      onShowUserSelectedUnsupportedTypeModal,
      addDocumentsToPolling,
      uploadedDocs,
      onUploadedDocs,
      documentValidationErrorMessage,
      onResetDocumentValidationErrorMessage,
      isErrorModalShown,
      setIsErrorModalShown,
      certidaoComercialPreFilledAccessCode,
      onResetCertidaoComercialPreFilledAccessCode,
    ],
  );

  const JobCategorizeFilesProps = useMemo(
    () => ({
      jobId: job.id,
      onShowUserSelectedUnsupportedTypeModal,
      addDocumentsToPolling,
      uploadedDocs,
      onUploadedDocs,
      showHeader: true,
    }),
    [job.id, onShowUserSelectedUnsupportedTypeModal, addDocumentsToPolling, uploadedDocs, onUploadedDocs],
  );

  const ReviewSettingsProps = useMemo(
    () => ({
      job,
      isGenerateReportPT,
      onGenerateReportPTToggle,
      isGenerateReportEN,
      onGenerateReportENToggle,
      checkRequiredDocumentsProvided,
    }),
    [
      job,
      isGenerateReportPT,
      onGenerateReportPTToggle,
      isGenerateReportEN,
      onGenerateReportENToggle,
      checkRequiredDocumentsProvided,
    ],
  );

  const JobProblemsAccordionProps = useMemo(
    () => ({
      job: job,
      index: isAccordionExpanded ? 0 : undefined,
      jobId: job.id,
      onIndexChange: () => onAccordionExpanded(false),
      isAccordionExpanded: isAccordionExpanded,
      onAccordionExpanded: onAccordionExpanded,
      isProblemsNavigationModalToggle: isProblemsNavigationModalToggle,
      onProblemsNavigationModalToggle: onProblemsNavigationModalToggle,
      hideExtraData: hideExtraData,
    }),
    [
      job,
      isAccordionExpanded,
      onAccordionExpanded,
      isProblemsNavigationModalToggle,
      onProblemsNavigationModalToggle,
      hideExtraData,
    ],
  );

  const JobDocumentsWidgetProps = useMemo(
    () => ({
      jobId: job.id,
      documentsValidationError,
      onDocumentsValidationError,
      isShowUserSelectedUnsupportedTypeModal,
      onShowUserSelectedUnsupportedTypeModal,
      addDocumentsToPolling,
      uploadedDocs,
      onUploadedDocs,
      documentValidationErrorMessage,
      onResetDocumentValidationErrorMessage,
      isErrorModalShown,
      setIsErrorModalShown,
      certidaoComercialPreFilledAccessCode,
      onResetCertidaoComercialPreFilledAccessCode,
    }),
    [
      job.id,
      documentsValidationError,
      onDocumentsValidationError,
      isShowUserSelectedUnsupportedTypeModal,
      onShowUserSelectedUnsupportedTypeModal,
      addDocumentsToPolling,
      uploadedDocs,
      onUploadedDocs,
      documentValidationErrorMessage,
      onResetDocumentValidationErrorMessage,
      isErrorModalShown,
      setIsErrorModalShown,
      certidaoComercialPreFilledAccessCode,
      onResetCertidaoComercialPreFilledAccessCode,
    ],
  );

  return {
    JobMainInfoProps,
    JobDocumentsStepProps,
    JobCategorizeFilesProps,
    ReviewSettingsProps,
    JobProblemsAccordionProps,
    JobDocumentsWidgetProps,
  };
};

export default useJobProps;
