import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Job } from 'api';
import { t } from 'i18next';

interface ErrorDisplayProps {
  error: FetchBaseQueryError | SerializedError | null | undefined;
  hasJobCheck?: boolean;
  job?: Job | null;
}

function isFetchBaseQueryError(error: any): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

export function ErrorDisplay({ error, hasJobCheck, job }: ErrorDisplayProps) {
  const [errorTitle, setErrorTitle] = useState<string>('');
  const [errorDescription, setErrorDescription] = useState<string>('');

  const errorTitleTextColor = useColorModeValue('brand.800', 'gray.200');
  const errorDescriptionTextColor = useColorModeValue('blue.900', 'white');

  useEffect(() => {
    let newErrorTitle = '';
    let newErrorDescription = '';

    if (hasJobCheck && !job) {
      newErrorTitle = t('pages.errors.404.title');
      newErrorDescription = t('pages.errors.404.description');
    }

    if (error && isFetchBaseQueryError(error)) {
      if (error.status === 403) {
        newErrorTitle = t('pages.errors.403.title');
        newErrorDescription = t('pages.errors.403.description');
      } else if (error.status === 404) {
        newErrorTitle = t('pages.errors.404.title');
        newErrorDescription = t('pages.errors.404.description');
      } else if (error.status === 500) {
        newErrorTitle = t('pages.errors.500.title');
        newErrorDescription = t('pages.errors.500.description');
      } else if (
        error.data &&
        typeof error.data === 'object' &&
        'message' in error.data &&
        typeof (error.data as { message: unknown }).message === 'string'
      ) {
        newErrorTitle = t('error');
        newErrorDescription = (error.data as { message: string }).message;
      }
    } else if (error) {
      newErrorTitle = t('error');
      newErrorDescription = t('pages.errors.unexpectedError');
    }

    setErrorTitle(newErrorTitle);
    setErrorDescription(newErrorDescription);
  }, [error, hasJobCheck, job]);

  return (
    <Flex align="center" justifyContent="center" height="100%" flexDir="column" gap="12px">
      <Text textAlign="center" fontSize="32px" fontWeight="bold" lineHeight="41px" color={errorTitleTextColor}>
        {errorTitle}
      </Text>
      <Text textAlign="center" fontWeight="bold" fontSize="20px" lineHeight="26px" color={errorDescriptionTextColor}>
        {errorDescription}
      </Text>
    </Flex>
  );
}
