import { Box } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmissionSigningStatus } from '../../../api';
import {
  signingDefaultLanguage,
  SigningLanguage,
  useCloneSigningMutation,
  useDeleteSigningMutation,
  useGetSigningQuery,
  useSubmitSigningMutation,
  useUpdateSigningMutation,
} from '../../../api/signing';
import { useDebounce } from '../../../hooks/useDebounce';
import { SigningDetailsWidget } from './widgets/SigningDetailsWidget';
import { useAppToast } from '../../../libs/ui/hooks';

export const SigningDetailPage = () => {
  const { t } = useTranslation();
  const { showSuccess, showApiError } = useAppToast();
  const navigate = useNavigate();

  const { signingId } = useParams<{ signingId: string }>();

  const [startPolling, setStartPolling] = useState(false);

  const {
    data: signing,
    isLoading,
    refetch,
  } = useGetSigningQuery(
    { signingId: signingId ?? '' },
    {
      pollingInterval: startPolling ? 700 : 0,
      skip: !signingId,
    },
  );

  useEffect(() => {
    setStartPolling(signing?.submission?.status === SubmissionSigningStatus.PREPARING);
  }, [signing?.submission?.status]);

  const [cloneSigning] = useCloneSigningMutation();
  const [submitSigning, { isLoading: isSubmitting }] = useSubmitSigningMutation();
  const [updateSigning, { isLoading: isloadingUpdate }] = useUpdateSigningMutation();
  const [deleteSigning] = useDeleteSigningMutation();

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [name, setName] = useState<string>('');
  const [isSerial, setIsSerial] = useState<boolean>(true);
  const [language, setLanguage] = useState<SigningLanguage>(signingDefaultLanguage);

  useEffect(() => {
    if (!initialDataLoaded && signing) {
      setName(signing.entity?.name ?? signing.name ?? '');
      setIsSerial(signing.isSerial ?? true);
      setLanguage(signing.language ?? signingDefaultLanguage);
      setInitialDataLoaded(true);
    }
  }, [signing, initialDataLoaded]);

  const debouncedName = useDebounce(name, { delay: 300 });

  const handleSubmit = useCallback(async () => {
    if (!signingId) {
      throw new Error('Signing ID is not defined');
    }

    try {
      await submitSigning({ id: signingId }).unwrap();
      refetch();
    } catch (error) {
      showApiError(error);
    }
  }, [signingId, submitSigning, showApiError]);

  const handleUpdateSigning = useCallback(
    async (data: { name?: string; isSerial?: boolean; language?: SigningLanguage }) => {
      if (isloadingUpdate || !signingId) {
        return;
      }

      if (!signingId) {
        throw new Error('Invalid application state! Signing ID is not defined');
      }

      try {
        await updateSigning({ id: signingId, ...data }).unwrap();
        refetch();
      } catch (error) {
        showApiError(error);
      }
    },
    [isloadingUpdate, signingId, showApiError, updateSigning],
  );

  const handleDeleteSigning = useCallback(async () => {
    if (!signingId) {
      throw new Error('Signing ID is not defined');
    }

    try {
      await deleteSigning({ id: signingId }).unwrap();
      showSuccess(t('pages.signings.actions.deletedSuccessfully'));
      navigate('/signings', { replace: true });
    } catch (error) {
      showApiError(error);
    }
  }, [deleteSigning, signingId, showApiError, navigate, showSuccess, t]);

  useEffect(() => {
    if (!initialDataLoaded || !debouncedName || !signingId) return;

    if (signing && debouncedName !== (signing.entity?.name ?? signing.name ?? '')) {
      handleUpdateSigning({ name: debouncedName });
    }
  }, [debouncedName, handleUpdateSigning, signing, signingId, initialDataLoaded]);

  const handleSerialToggle = useCallback(
    (value: boolean) => {
      setIsSerial(value);
      handleUpdateSigning({ isSerial: value });
    },
    [handleUpdateSigning],
  );

  const handleLanguageChange = useCallback(
    (value: SigningLanguage) => {
      setLanguage(value);
      handleUpdateSigning({ language: value });
    },
    [handleUpdateSigning],
  );

  const handleCloneRejectedSigning = useCallback(async () => {
    if (!signingId) {
      throw new Error('Signing ID is not defined');
    }

    try {
      const newSigning = await cloneSigning({ id: signingId }).unwrap();
      showSuccess(t('pages.signings.actions.clonedSuccessfully'));
      navigate(`/signings/${newSigning.id}`, { replace: true });
    } catch (error) {
      showApiError(error);
    }
  }, [cloneSigning, signingId, showSuccess, t, showApiError, navigate]);

  useEffect(() => {
    if (
      (signing?.submission?.status === SubmissionSigningStatus.READY_TO_SEND ||
        signing?.submission?.status === SubmissionSigningStatus.PREPARED_FILE_TOO_LARGE) &&
      signingId
    ) {
      navigate(`/signings/${signingId}/confirm`, { replace: true });
    }
  }, [navigate, signing?.submission?.status, signingId]);

  if (isLoading) {
    return <Box>...{t('loading')}</Box>;
  }

  if (!signing) {
    return <Box>...{t('pages.signing.signingFetchError')}</Box>;
  }

  return (
    <SigningDetailsWidget
      signing={signing}
      name={name}
      isSerial={isSerial}
      language={language}
      onSerialToggle={handleSerialToggle}
      onLanguageChange={handleLanguageChange}
      onDocumentsChanged={() => {
        refetch();
      }}
      onSubmit={handleSubmit}
      onDeleteSigning={handleDeleteSigning}
      isLoadingSubmit={isSubmitting}
      onSingersCountChanged={() => {
        refetch();
      }}
      onNameChanged={e => setName(e)}
      onCloneRejectedSigning={handleCloneRejectedSigning}
    />
  );
};
