import { ReactNode, useState } from 'react';
import { FormControl, FormLabel, InputGroup, InputLeftElement, Text, useColorModeValue, Input } from '@chakra-ui/react';
import { NumericFormat, NumberFormatValues } from 'react-number-format';

export interface PriceInputFieldProps {
  value?: number;
  label: string;
  prefix?: string;
  decimalScale?: number;

  onChange?: (value: number | string) => void;
  onBlur?: (value: number | string) => void;

  isReadonly?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;

  leftChildren?: ReactNode;

  maxLength?: number;

  thousandSeparator?: string;
  decimalSeparator?: string;
  useFormattedValue?: boolean;
}

export function PriceInputField({
  value,
  label,
  prefix = '€ ',
  decimalScale = 2,
  onChange,
  onBlur,
  isReadonly,
  isInvalid,
  isRequired,
  leftChildren,
  maxLength,
  thousandSeparator = '.',
  decimalSeparator = ',',
  useFormattedValue = false,
}: PriceInputFieldProps) {
  const textColor = useColorModeValue('navy.750', 'white');
  const errorBorderColor = useColorModeValue('red.500', 'red.500');

  const [displayValue, setDisplayValue] = useState<string>(value?.toString() || '');
  const [rawValue, setRawValue] = useState<number>(value || 0);

  const handleBlur = () => {
    if (isReadonly || !onBlur) return;
    const result = useFormattedValue ? reformatValueWithPrefix(displayValue) : rawValue;
    onBlur(result);
  };

  const handleValueChange = (values: NumberFormatValues) => {
    const { floatValue, formattedValue } = values;

    setDisplayValue(formattedValue);
    setRawValue(floatValue || 0);

    onChange?.(useFormattedValue ? reformatValueWithPrefix(formattedValue) : floatValue || 0);
  };

  const isAllowed = (values: NumberFormatValues) => {
    const { floatValue } = values;
    return !maxLength || !floatValue || floatValue.toString().split(decimalSeparator)[0].length <= maxLength;
  };

  const reformatValueWithPrefix = (value: string): string => {
    const cleanValue = value.replace(prefix, '').trim();
    const cleanPrefix = prefix.trim();
    return `${cleanValue} ${cleanPrefix}`;
  };

  return (
    <FormControl maxW="100%" overflow="hidden" isInvalid={isInvalid} p="1px">
      <FormLabel mb="4px" display="flex" title={label}>
        <Text className="no-text-wrap" as="span" textOverflow="ellipsis" overflow="hidden">
          {label}
        </Text>
        {isRequired && (
          <Text as="span" color="red">
            {' *'}
          </Text>
        )}
      </FormLabel>

      <InputGroup>
        {leftChildren && (
          <InputLeftElement pointerEvents="none" height={'100%'}>
            {leftChildren}
          </InputLeftElement>
        )}

        <NumericFormat
          customInput={Input}
          prefix={prefix}
          thousandSeparator={thousandSeparator}
          decimalSeparator={decimalSeparator}
          decimalScale={decimalScale}
          fixedDecimalScale
          allowNegative={false}
          height="50px"
          borderColor={isInvalid ? errorBorderColor : undefined}
          borderRadius="10px"
          color={textColor}
          placeholder={label}
          disabled={isReadonly}
          value={displayValue}
          onValueChange={handleValueChange}
          onBlur={handleBlur}
          isAllowed={isAllowed}
        />
      </InputGroup>
    </FormControl>
  );
}
