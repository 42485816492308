import { Text } from '@chakra-ui/react';

export interface TableHeaderProps {
  children: React.ReactNode;
}

export const TableHeader = ({ children }: TableHeaderProps) => {
  return (
    <Text
      justifyContent="space-between"
      fontFamily="DM Sans"
      fontSize="14px"
      fontWeight="500"
      lineHeight="24px"
      color="gray.400"
    >
      {children}
    </Text>
  );
};
