import { Tab, TabList, TabPanel, TabPanels, Tabs, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export interface TabProps<TValue = string | number> {
  /**
   * Only one null value is allowed across all tabs
   */
  value?: TValue;
  title?: string;
  titleKey?: string;
  children?: React.ReactNode;

  /**
   * Optional remark to display on the right of tab title
   */
  remark?: string;
}

export interface TabsProps<TValue = string | number> {
  tabs: TabProps<TValue>[];
  selectedTab: TValue | null;
  onChange?: (index: TValue | null) => void;

  isHideContent?: boolean;
}

export const VCTabs = <TValue,>({ tabs, selectedTab, onChange, isHideContent }: TabsProps<TValue>) => {
  const { t } = useTranslation();

  const textColor = useColorModeValue('_gray.400', 'blue.500');
  const selectedTextColor = useColorModeValue('_brand.1050', 'blue.500');
  const tabBg = useColorModeValue('transparent', 'transparent');
  const selectedTabBg = useColorModeValue('_gray.100', '_gray.360');
  const remarkBg = useColorModeValue('green.400', 'green.400');
  const remarkTextColor = useColorModeValue('white', 'white');

  return (
    <Tabs
      tabIndex={tabs.findIndex(i => i.value === selectedTab)}
      onChange={e => onChange?.(tabs[e].value ?? null)}
      variant="unstyled"
    >
      <TabList onClick={e => e.stopPropagation()} gap={'16px'} flexWrap={'wrap'}>
        {tabs.map((tab, idx) => (
          <Tab
            key={idx}
            bg={tabBg}
            gap="4px"
            color={textColor}
            _selected={{ color: selectedTextColor, bg: selectedTabBg }}
            borderRadius="10px"
            overflow={'hidden'}
            fontSize={'16px'}
            fontWeight={700}
          >
            {tab.titleKey ? t(tab.titleKey) : tab.title}

            {tab.remark && (
              <Text
                fontSize={'12px'}
                fontWeight="bold"
                color={remarkTextColor}
                bg={remarkBg}
                minH="18px"
                minW="18px"
                borderRadius={'50%'}
              >
                {tab.remark}
              </Text>
            )}
          </Tab>
        ))}
      </TabList>

      {!isHideContent && (
        <TabPanels>
          {tabs.map((tab, idx) => (
            <TabPanel key={idx}>{tab.children}</TabPanel>
          ))}
        </TabPanels>
      )}
    </Tabs>
  );
};
