import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex } from '@chakra-ui/react';
import { useState } from 'react';

export interface MaybeWrapAccordionProps {
  children: React.ReactNode;
  buttonChildren: React.ReactNode;
  isAccordion?: boolean;
  isExpanded?: boolean;
  onToggle?: (expanded: boolean) => void;
  buttonColor?: string;
}

export function MaybeWrapAccordion({
  children,
  buttonChildren,
  isAccordion,
  isExpanded: isControlledExpanded,
  onToggle,
  buttonColor,
}: MaybeWrapAccordionProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const isControlled = isControlledExpanded !== undefined;
  const expanded = isControlled ? isControlledExpanded : isExpanded;

  const handleButtonClick = () => {
    if (!isControlled) {
      setIsExpanded(!isExpanded);
    }
    onToggle?.(!expanded);
  };

  return isAccordion ? (
    <Accordion index={expanded ? 0 : -1} allowMultiple allowToggle border="none">
      <AccordionItem border="none">
        <AccordionButton
          p="0"
          _focus={{ boxShadow: 'none' }}
          _hover={{ bg: undefined }}
          pr="24px"
          maxW="100%"
          overflow="hidden"
          onClick={handleButtonClick}
        >
          <Flex flex={1}>{buttonChildren}</Flex>
          <AccordionIcon color={buttonColor} />
        </AccordionButton>

        <AccordionPanel p="0">{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : (
    <Flex direction="column" gap="16px">
      {buttonChildren}
      {children}
    </Flex>
  );
}
