import { api } from './api';

export enum RightOfFirstRefusalTypeOfBusiness {
  BUY_AND_SELL = 'BUY_AND_SELL',
  PAYMENT_IN_PAYMENT = 'PAYMENT_IN_PAYMENT',
}

export enum RightOfFirstRefusalItemsMeasure {
  METERS_SQUARE = 'METERS_SQUARE',
  ACRE = 'ACRE',
}

export enum RightOfFirstRefusalDensity {
  BUSINESS = 'BUSINESS',
  HOUSING = 'HOUSING',
  INDUSTRY = 'INDUSTRY',
  OTHER = 'OTHER',
}

export interface RightOfFirstRefusalData {
  name: string;
  email: string;
  nif: string;
  address: string;
  certidaoIdentifier: string;
  identifier_conservatória: string;
  identifier_parish: string;
  identifier_parish_id: string;
  price?: string;
  propertyLocation?: string;
  privateGrossArea?: string;

  simulateRequest?: boolean;
}

export interface RightOfFirstRefusalResult {
  success: boolean;
}

export interface RightOfFirstRefusalRelatedParty {
  name: string;
  nif: string;
}

export interface RightOfFirstRefusalDto {
  jobId: string;

  name: string;
  email: string;
  nif: string;
  address: string;
  phone: string;

  certidaoIdentifier: string;
  descriptionOfSheet: string;
  matrixArticle?: string;
  autonomousFraction: string;
  identifier_parish_id: string;

  shareShare?: string;
  privateGrossArea: string;
  privateGrossAreaItemsMeasure: RightOfFirstRefusalItemsMeasure;
  totalArea?: string;
  totalAreaItemsMeasure?: RightOfFirstRefusalItemsMeasure;
  isLeased: boolean;
  propertyLocation: string;
  destiny: RightOfFirstRefusalDensity;

  typeOfBusiness: RightOfFirstRefusalTypeOfBusiness;
  price: string;
  expectedDealDate: string;
  comments?: string;

  buyers: { name: string; nif: string }[];
  sellers: { name: string; nif: string }[];

  simulateRequest?: boolean;
}

const rightOfFirstRefusalModalApi = api.injectEndpoints({
  endpoints: build => ({
    getRightOfFirstRefusalData: build.query<RightOfFirstRefusalData, { jobId: string }>({
      query: ({ jobId }) => ({ url: `/job/${jobId}/getRightOfFirstRefusalData` }),
    }),
    requestRightOfFirstRefusal: build.mutation<RightOfFirstRefusalResult, RightOfFirstRefusalDto>({
      query: dto => ({ url: `/job/${dto.jobId}/requestRightOfFirstRefusal`, method: 'POST', body: dto }),
    }),
  }),
});

export const {
  useLazyGetRightOfFirstRefusalDataQuery,
  useRequestRightOfFirstRefusalMutation,
  endpoints: RightOfFirstRefusalEndpoints,
} = rightOfFirstRefusalModalApi;

export const mockRightOfFirstRefusalData: RightOfFirstRefusalData = {
  // jobId: '1',
  name: 'John Doe',
  email: 'john.doe@example.com',
  nif: '123456789',
  address: 'Lisbon',
  // phone: '',

  certidaoIdentifier: '2450/20100122 - D',
  identifier_conservatória: 'Conservatória do Registo Predial de Lisboa',
  identifier_parish: 'Santa Engrácia',
  identifier_parish_id: '110629',

  // privateGrossAreaItemsMeasure: RightOfFirstRefusalItemsMeasure.METERS_SQUARE,
  // totalAreaItemsMeasure: RightOfFirstRefusalItemsMeasure.METERS_SQUARE,
  // typeOfBusiness: RightOfFirstRefusalTypeOfBusiness.BUY_AND_SELL,
  price: '',
  propertyLocation: 'Avenida de Ceuta, nº 20',
  privateGrossArea: '115,45',
};
