import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import {
  CertificateRenewalDto,
  RelatedParty,
  RelatedPartyRelationType,
  useLazyGetCertificateRenewalDataQuery,
  useRenewCertificateMutation,
} from 'api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useRenewalRequestForm } from './useRenewalRequestForm';
import { CertificateRenewalSuccess } from './CertificateRenewalSuccess';
import { environment } from 'environments/environment';
import { AppButton, AppButtonType, AppButtonColorScheme } from '../../libs/ui/atoms/src/lib/appButton';
import { useRelatedParties } from '../../views/admin/job/widgets/AddContractsWidget/useRelatedParties';
import { getAddressParts } from 'utils/get-address-parts';

export interface CertificateRenewalActionProps {
  jobId: string;
  problemId: string;
}

export const CertificateRenewalAction = ({ jobId, problemId }: CertificateRenewalActionProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isSimulateRequest, setIsSimulateRequest] = useState(false);

  const [getCertificateRenewalData, { isLoading: isLoadingData, data }] = useLazyGetCertificateRenewalDataQuery({});
  const [renewCertificate, { isLoading }] = useRenewCertificateMutation({});

  const { form, dispatch, validate } = useRenewalRequestForm();

  interface InitialOrgData {
    name: string;
    nif?: string;
    email?: string;
    address?: string;
    localidade?: string;
    postalCode?: string;
  }

  const [initialOrgData, setInitialOrgData] = useState<InitialOrgData>({ name: '' });
  const { sellers }: { sellers: RelatedParty[] } = useRelatedParties(jobId, true);

  const open = useCallback(async () => {
    try {
      const data = await getCertificateRenewalData({ jobId, problemId });
      if (data.isError || !data.data) {
        toast({
          title: t('certificateRenewal.unableToGetRenewalData'),
          description: ((data.error as FetchBaseQueryError).data as Error)?.message,
          status: 'error',
          duration: 5000,
        });
        return;
      }

      const orgData = {
        name: data.data.name,
        nif: data.data.nif,
        email: data.data.email,
        address: data.data.address,
        localidade: data.data.location,
        postalCode: data.data.postalCode,
      };

      setInitialOrgData(orgData);

      dispatch({ type: 'setInitialState', data: data.data });
      onOpen();
    } catch (error) {
      toast({
        title: t('certificateRenewal.unableToGetRenewalData'),
        description: (error as Error).message,
        status: 'error',
        duration: 5000,
      });
    }
  }, [dispatch, getCertificateRenewalData, jobId, onOpen, t, toast, problemId]);

  const close = useCallback(() => {
    dispatch({ type: 'setInitialState', data: {} });
    onClose();
  }, [onClose, dispatch]);

  const onSubmit = useCallback(async () => {
    const validationMessages = validate();
    if (validationMessages.length > 0) {
      toast({
        title: t('validationError'),
        description: validationMessages.map(i => t(i)).join(', \n'),
        status: 'error',
        duration: 5000,
      });
      return;
    }

    try {
      const result = await renewCertificate({
        ...(form as CertificateRenewalDto),
        jobId,
        problemId: problemId,
        simulateRequest: isSimulateRequest,
      }).unwrap();
      if (!result.success) {
        return;
      }

      close();
      setIsSuccessOpen(true);
    } catch (error) {
      console.log(error);

      toast({
        title: t('certificateRenewal.unableToRenewCertificate'),
        description: ((error as FetchBaseQueryError).data as Error)?.message,
        status: 'error',
        duration: 5000,
      });
    }
  }, [validate, toast, t, renewCertificate, form, jobId, close, isSimulateRequest, problemId]);

  const onSuccessClose = useCallback(() => {
    setIsSuccessOpen(false);
  }, [setIsSuccessOpen]);

  const textColor = useColorModeValue('navy.750', 'white');

  const handleCopyFirstSellerDetails = () => {
    const firstSeller = sellers[0];

    dispatch({ type: 'setName', name: firstSeller.name });
    dispatch({ type: 'setNifNips', nif: firstSeller.nif ?? '' });
    dispatch({ type: 'setEmail', email: firstSeller.email ?? '' });

    if (firstSeller.relationType === 'PERSON') {
      const address = firstSeller.address ?? '';
      const { addressLine, postalCode, location } = getAddressParts(address);

      dispatch({ type: 'setAddress', address: addressLine ?? '' });
      dispatch({ type: 'setLocation', location: location ?? '' });
      dispatch({ type: 'setPostalCode', postalCode: postalCode ?? '' });
    }

    if (firstSeller.relationType === 'COMPANY') {
      dispatch({ type: 'setAddress', address: firstSeller.corpAddress ?? '' });
      dispatch({ type: 'setLocation', location: firstSeller.corpAddressParish ?? '' });
      dispatch({ type: 'setPostalCode', postalCode: firstSeller.corpAddressPostalCode ?? '' });
    }

    if (firstSeller.relationType === 'HEIR') {
      dispatch({ type: 'setAddress', address: '' });
      dispatch({ type: 'setLocation', location: '' });
      dispatch({ type: 'setPostalCode', postalCode: '' });
    }
  };

  const handleCopyOrganizationDetails = () => {
    if (initialOrgData) {
      dispatch({ type: 'setName', name: initialOrgData.name ?? '' });
      dispatch({ type: 'setNifNips', nif: initialOrgData.nif ?? '' });
      dispatch({ type: 'setEmail', email: initialOrgData.email ?? '' });
      dispatch({ type: 'setAddress', address: initialOrgData.address ?? '' });
      dispatch({ type: 'setLocation', location: initialOrgData.localidade ?? '' });
      dispatch({ type: 'setPostalCode', postalCode: initialOrgData.postalCode ?? '' });
    }
  };

  const getSellerInfoText = useCallback(
    (sellers: RelatedParty[]) => {
      if (sellers.length) {
        const firstSeller = sellers[0];
        const name = firstSeller.name.split(' ');
        if (firstSeller.name) {
          if (name.length > 1) {
            return `${t('certificateRenewal.copySellerInfo')} (${name[0]} ${name.slice(-1)}) `;
          } else {
            return `${t('certificateRenewal.copySellerInfo')} (${name})`;
          }
        }
      }
      return t('certificateRenewal.copySellerInfo');
    },
    [t],
  );

  const getOrganizationInfoText = useCallback(
    (initialOrgData: InitialOrgData) => {
      if (initialOrgData.name) {
        const name = initialOrgData.name.split(' ');
        if (name.length > 1) {
          return `${t('certificateRenewal.copyOrgDetails')} (${name[0]} ${name.slice(-1)})`;
        } else {
          return `${t('certificateRenewal.copyOrgDetails')} (${name})`;
        }
      }
      return t('certificateRenewal.copyOrgDetails');
    },
    [t],
  );

  return (
    <>
      <AppButton
        buttonType={AppButtonType.PRIMARY}
        title={t('update')}
        isLoading={isLoadingData}
        onClick={open}
        colorSchemes={[AppButtonColorScheme.SECONDARY]}
      />

      <CertificateRenewalSuccess isOpen={isSuccessOpen} onClose={onSuccessClose} />

      <Modal size="xl" isOpen={isOpen} onClose={close} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent color={textColor} maxH={'calc(100% - 0rem)'} maxW={'900px'} p="24px" borderRadius="16px">
          <ModalHeader p="0" fontSize="24px">
            {t('certificateRenewal.form.title')}
          </ModalHeader>
          <ModalCloseButton right="24px" top="none" />
          <ModalBody p="24px 1px">
            <Flex direction="column" gap="32px">
              <Flex direction="column" gap="12px">
                <Flex direction="row" gap="12px" alignItems={'center'}>
                  <Text fontSize="16px" fontWeight="700" style={{ whiteSpace: 'nowrap' }}>
                    {t('certificateRenewal.form.detailsOfApplicant')}
                  </Text>

                  <Divider orientation="horizontal" pt={'2px'} opacity={1} />
                </Flex>

                <Grid columnGap={'24px'} rowGap={'16px'} gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}>
                  {sellers.length > 0 && (
                    <AppButton
                      mt={4}
                      width="100%"
                      onClick={handleCopyFirstSellerDetails}
                      buttonType={AppButtonType.XSMALL}
                      title={getSellerInfoText(sellers)}
                      colorSchemes={[AppButtonColorScheme.TRANSPARENT]}
                    />
                  )}
                  <AppButton
                    mt={4}
                    width="100%"
                    onClick={handleCopyOrganizationDetails}
                    buttonType={AppButtonType.XSMALL}
                    title={getOrganizationInfoText(initialOrgData)}
                    colorSchemes={[AppButtonColorScheme.TRANSPARENT]}
                  />
                  {sellers.length === 0 && <Box mt={4} width="100%" />}

                  <FormControl>
                    <FormLabel>{t('certificateRenewal.form.name')}</FormLabel>
                    <Input
                      color={textColor}
                      placeholder={t('certificateRenewal.form.name')}
                      value={form.name}
                      onChange={e => dispatch({ type: 'setName', name: e.target.value })}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{t('certificateRenewal.form.email')}</FormLabel>
                    <Input
                      color={textColor}
                      placeholder={t('certificateRenewal.form.email')}
                      value={form.email}
                      onChange={e => dispatch({ type: 'setEmail', email: e.target.value })}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{t('certificateRenewal.form.nifNips')}</FormLabel>
                    <Input
                      color={textColor}
                      placeholder={t('certificateRenewal.form.nifNips')}
                      value={form.nif}
                      onChange={e => dispatch({ type: 'setNifNips', nif: e.target.value })}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{t('certificateRenewal.form.address')}</FormLabel>
                    <Input
                      color={textColor}
                      placeholder={t('certificateRenewal.form.address')}
                      value={form.address}
                      onChange={e => dispatch({ type: 'setAddress', address: e.target.value })}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{t('certificateRenewal.form.location')}</FormLabel>
                    <Input
                      color={textColor}
                      placeholder={t('certificateRenewal.form.location')}
                      value={form.location}
                      onChange={e => dispatch({ type: 'setLocation', location: e.target.value })}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{t('certificateRenewal.form.postalCode')}</FormLabel>
                    <Input
                      color={textColor}
                      placeholder={t('certificateRenewal.form.postalCode')}
                      value={form.postalCode}
                      onChange={e =>
                        dispatch({
                          type: 'setPostalCode',
                          postalCode: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
              </Flex>

              <Flex direction="column" gap="12px">
                <Flex direction="row" gap="12px" alignItems={'center'}>
                  <Text fontSize="16px" fontWeight="700" style={{ whiteSpace: 'nowrap' }}>
                    {t('certificateRenewal.form.detailsOfCertidaoPermanente')}
                  </Text>

                  <Divider orientation="horizontal" pt={'2px'} opacity={1} />
                </Flex>

                <Grid columnGap={'24px'} rowGap={'16px'} gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}>
                  <FormControl>
                    <FormLabel>{t('certificateRenewal.form.identifierParish')}</FormLabel>
                    <Input
                      readOnly
                      color={textColor}
                      placeholder={t('certificateRenewal.form.identifierParish')}
                      value={data?.identifier_parish}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{t('certificateRenewal.form.identifierConservatoria')}</FormLabel>
                    <Input
                      readOnly
                      color={textColor}
                      placeholder={t('certificateRenewal.form.identifierConservatoria')}
                      value={data?.identifier_conservatória}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{t('certificateRenewal.form.certidaoIdentifier')}</FormLabel>
                    <Input
                      readOnly
                      color={textColor}
                      placeholder={t('certificateRenewal.form.certidaoIdentifier')}
                      value={data?.certidaoIdentifier}
                    />
                  </FormControl>

                  {environment.stage !== 'prod' && (
                    <Checkbox isChecked={isSimulateRequest} onChange={e => setIsSimulateRequest(!isSimulateRequest)}>
                      Simulate request
                    </Checkbox>
                  )}
                </Grid>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent="center" gap="24px" p="0">
            <AppButton
              buttonType={AppButtonType.PRIMARY_MAIN}
              isLoading={isLoading}
              onClick={onSubmit}
              title={t('certificateRenewal.submit')}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
