import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Hide,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { LuBell, LuGripVertical, LuTrash } from 'react-icons/lu';
import { SignerForm } from './signer-form';
import { Signer, SignerStatus, SignerUpdateDto } from 'api/signer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SignerFormState, useSignerForm } from '../signer/useSignerForm';
import { Account, UserApprove, UserAttention, UserDelete } from '../../../../libs/ui/atoms/src';
import { useTranslation } from 'react-i18next';
import { SigningType, useNotifySignersMutation } from '../../../../api/signing';
import { SignerSigningTypeSelect } from './signer-signing-type-select';
import { isEqual } from '../../../../utils/is-equal';
import { SubmissionSigningStatus } from '../../../../api';
import { useAppToast } from '../../../../libs/ui/hooks';
import { AppButton, AppButtonType, AppButtonColorScheme } from '../../../../libs/ui/atoms/src/lib/appButton';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

export interface SignerItemProps {
  isInConfirmView?: boolean;
  isShowBorderBottom?: boolean;
  isDisabled?: boolean;
  signer?: Signer;
  onDelete: () => void | Promise<void>;
  onUpdate?: (dto: SignerUpdateDto) => Promise<void>;
  onCreate?: (values: SignerFormState) => Promise<void>;
  signingStatus?: SubmissionSigningStatus;
  draggableAttributes?: DraggableAttributes;
  draggableListeners?: SyntheticListenerMap;
  isDragging?: boolean;
}

export const SignerItem = (props: SignerItemProps) => {
  const {
    isInConfirmView,
    isShowBorderBottom,
    signer,
    isDisabled,
    onCreate,
    onUpdate,
    onDelete,
    signingStatus,
    draggableAttributes,
    draggableListeners,
    isDragging,
  } = props;

  const isInvalidSigner = signer?.isValid === false;

  const { t } = useTranslation();
  const { showSuccess, showError } = useAppToast();

  const [isExpanded, setIsExpanded] = useState(false);
  const [notifySigner, { isLoading: isNotifying }] = useNotifySignersMutation();

  const { form, dispatch, validate } = useSignerForm();

  const latestFormRef = useRef(form);

  const handleUpdate = useCallback(async () => {
    try {
      const currentForm = latestFormRef.current;
      const validationMessages = validate();

      const isSomeFieldFilled = Object.entries(currentForm).some(
        ([key, value]) => key !== 'signingType' && value !== '' && value !== null && value !== undefined,
      );

      if (!isSomeFieldFilled) {
        return;
      }

      const preparedChanges = {
        name: currentForm.name || null,
        email: currentForm.email || null,
        phone: currentForm.phone
          ? currentForm.phone.startsWith('+')
            ? currentForm.phone
            : `+${currentForm.phone}`
          : null,
        nif: currentForm.nif || null,
        signingType: currentForm.signingType,
        idDocumentType: currentForm.idDocumentType || null,
        idDocumentIssuerCountry: currentForm.idDocumentIssuerCountry || null,
        idDocumentNumber: currentForm.idDocumentNumber || null,
        idDocumentExpiryDate: currentForm.idDocumentExpiryDate || null,
      };

      if (signer && isEqual(preparedChanges, signer)) {
        return;
      }

      if (validationMessages.length) {
        showError(validationMessages.map(i => t(i)).join(', \n'));
        return;
      }

      if (!signer && onCreate) {
        await onCreate(preparedChanges as SignerFormState);
        return;
      }

      if (signer && onUpdate) {
        await onUpdate({ id: signer.id, ...preparedChanges } as SignerUpdateDto);
      }
    } catch (error) {
      showError((error as { data: Error }).data?.message);
    }
  }, [signer, onCreate, onUpdate, t, showError, validate]);

  const handleFieldBlur = useCallback(() => {
    handleUpdate();
  }, [handleUpdate]);

  useEffect(() => {
    latestFormRef.current = form;
  }, [form]);

  useEffect(() => {
    if (!signer || signer?.isValid === false) {
      setIsExpanded(true);
    } else {
      const isNewSigner = new Date().getTime() - new Date(signer.createdAt).getTime() < 2000;
      if (isNewSigner) {
        setIsExpanded(true);
      }
    }
  }, [signer]);

  useEffect(() => {
    if (signer) {
      dispatch({
        type: 'setInitialState',
        value: {
          name: signer?.name,
          email: signer?.email,
          phone: signer?.phone ?? '',
          nif: signer?.nif,
          idDocumentType: signer?.idDocumentType,
          idDocumentIssuerCountry: signer?.idDocumentIssuerCountry,
          idDocumentNumber: signer?.idDocumentNumber,
          idDocumentExpiryDate: signer?.idDocumentExpiryDate,
          signingType: signer?.signingType ?? SigningType.SMS,
        },
      });
    }
  }, [dispatch, signer]);

  useEffect(() => {
    handleUpdate();
  }, [form.signingType]);

  const handleNotifySigner = useCallback(async () => {
    try {
      if (!signer) {
        throw new Error('Signer is undefined');
      }

      await notifySigner({ signerIds: [signer.id] }).unwrap();
      showSuccess(t('signer.notified'));
    } catch (error) {
      showError((error as { data: Error }).data?.message);
    }
  }, [notifySigner, signer, t, showSuccess, showError]);

  const bgColor = useColorModeValue('blue.50', 'navy.900');
  const lightTextColor = useColorModeValue('#A3AED0', '#A3AED0');

  const textColor = useColorModeValue('_blue.900', 'white');
  const relationIconColor = useColorModeValue('_blue.900', 'white');

  const dndColorIndex = useColorModeValue('_gray.700', 'white');
  const dndColorIndexActive = useColorModeValue('white', 'white');
  const dndBgColorIndex = useColorModeValue('_gray.200', 'whiteAlpha.400');
  const dndBgColorIndexActive = useColorModeValue('_brand.550', '_brand.550');

  return (
    <Accordion
      index={isExpanded ? 0 : -1}
      allowMultiple
      allowToggle
      borderLeft="none"
      borderRight="none"
      borderBottom={isShowBorderBottom ? '1px solid' : 'none'}
      borderColor={'gray.400'}
    >
      <AccordionItem border="none">
        <AccordionButton
          _expanded={{ bg: bgColor }}
          _focus={{ outline: 'none' }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {!isDisabled && signer && (
            <Box
              {...draggableAttributes}
              display="flex"
              alignItems="center"
              cursor="grab"
              mr="8px"
              gap="16px"
              onPointerDown={e => {
                for (const key in draggableListeners) {
                  draggableListeners[key](e);
                }
              }}
            >
              <LuGripVertical size="22px" color={isDragging ? dndBgColorIndexActive : dndBgColorIndex} />
              <Box
                minH="24px"
                minW="24px"
                borderRadius="50%"
                bg={isDragging ? dndBgColorIndexActive : dndBgColorIndex}
                color={isDragging ? dndColorIndexActive : dndColorIndex}
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontSize="16px"
                fontWeight="bold"
                mr="16px"
              >
                {(signer?.orderIndex || 0) + 1}
              </Box>
            </Box>
          )}
          {!signer || isInvalidSigner ? (
            <UserAttention color={'red.400'} h={'20px'} w={'20px'} />
          ) : (
            <>
              {signer?.status === SignerStatus.INIT && <Account color={relationIconColor} h={'20px'} w={'20px'} />}
              {signer?.status === SignerStatus.SIGNED && <UserApprove color={'green.400'} h={'24px'} w={'24px'} />}
              {signer?.status === SignerStatus.REJECTED && <UserDelete color={'red.500'} h={'24px'} w={'24px'} />}
            </>
          )}

          <Box
            display="flex"
            flexDirection="column"
            as="span"
            flex="1"
            textAlign="left"
            ml="24px"
            mr="16px"
            minH={'45px'}
          >
            <Text fontSize="16px" fontWeight="bold" color={!signer || isInvalidSigner ? 'red.400' : textColor}>
              {form.name || 'Name'}
            </Text>
            <Text fontSize="14px" fontWeight="500" color={lightTextColor}>
              {form.email || 'Email'}
            </Text>
          </Box>

          {signingStatus === SubmissionSigningStatus.SENT && signer?.status === SignerStatus.INIT && (
            <AppButton
              buttonType={AppButtonType.XSMALL}
              leftIcon={<LuBell size="16px" />}
              onClick={handleNotifySigner}
              isLoading={isNotifying}
              mr="25px"
              title={t('signer.notify')}
              colorSchemes={[AppButtonColorScheme.WARNING]}
            />
          )}

          {isDisabled ? (
            <SignerSigningTypeSelect
              isDisabled={isDisabled}
              signingType={form.signingType}
              onChange={value => dispatch({ type: 'setSigningType', value })}
            />
          ) : (
            <Hide breakpoint="(max-width: 1500px)">
              <SignerSigningTypeSelect
                isDisabled={isDisabled}
                signingType={form.signingType}
                onChange={value => dispatch({ type: 'setSigningType', value })}
              />
            </Hide>
          )}

          {!isDisabled && (
            <AppButton
              leftIcon={<LuTrash />}
              mr="16px"
              buttonType={AppButtonType.XSMALL}
              onClick={e => {
                e.stopPropagation();
                onDelete();
              }}
              title={t('delete')}
              colorSchemes={[AppButtonColorScheme.DANGER]}
            />
          )}
          <AccordionIcon onClick={() => setIsExpanded(!isExpanded)} />
        </AccordionButton>

        <AccordionPanel pb={4} bgColor={bgColor} color={lightTextColor} pt={'4'}>
          <SignerForm
            isInConfirmView={isInConfirmView}
            form={form}
            dispatch={dispatch}
            isDisabled={isDisabled}
            onFieldBlur={handleFieldBlur}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
