import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, Table, Tag, Tbody, Td, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { AppButton, AppButtonColorScheme, AppButtonType, RecycleBing } from 'libs/ui/atoms/src';
import { useTypedSelector } from '../../../../../store';
import {
  DocumentStatus,
  DocumentTranslatedCategoryByType,
  DocumentType,
  DocumentTypesOrdered,
  JobDocument,
  JobDocumentUpdateDto,
  useDeleteJobDocumentMutation,
  useUpdateJobDocumentMutation,
} from '../../../../../api';
import { selectJobDocuments } from '../../../../../store/job';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import InputCell from 'components/table/InputCell';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { ApproveTick, ExclamationMark } from 'libs/ui/atoms/src/lib/icons/icons';
import { DocumentOutFilledIcon, ReloadIcon } from 'components/icons/Icons';
import { TableHeader } from 'libs/ui/table';

const IconByStatus: Record<DocumentStatus, JSX.Element> = {
  [DocumentStatus.INITIALIZED]: <ApproveTick color="green.200" boxSize={6} />,
  [DocumentStatus.CREATED]: <ReloadIcon boxSize={6} />,
  [DocumentStatus.ERRORED]: <ExclamationMark color="red.500" boxSize={6} />,
  [DocumentStatus.FATAL]: <ExclamationMark color="red.500" boxSize={6} />,
  [DocumentStatus.UNACCEPTABLE]: <ExclamationMark color="orange.500" boxSize={6} />,
  [DocumentStatus.PROPERTY_ID_MISMATCH]: <ExclamationMark color="orange.500" boxSize={6} />,
  [DocumentStatus.NO_DATA_FOUND_ON_DOCUMENT]: <ExclamationMark color="red.500" boxSize={6} />,
};

const columnHelper = createColumnHelper<JobDocument>();

const ColumnsWidth = ['5%', '55%', '20%', '20%'];
const ColumnsMinWidth = ['0', '250px', '0', '0'];

const errorMEssageUnsupportedTypeModalTrigger = 'Cannot set system determinable document type';

export interface JobCategorizeFilesProps {
  jobId: string;
  onShowUserSelectedUnsupportedTypeModal: (isShowUserSelectedUnsupportedTypeModal: boolean) => void;
  addDocumentsToPolling: (documents: JobDocument[]) => void;
  uploadedDocs: JobDocument[];
  onUploadedDocs: (uploadedDocs: JobDocument[]) => void;
  showHeader: boolean;
}

export function JobCategorizeFiles({
  jobId,
  onShowUserSelectedUnsupportedTypeModal,
  addDocumentsToPolling,
  uploadedDocs,
  onUploadedDocs,
  showHeader,
}: JobCategorizeFilesProps) {
  const { t } = useTranslation();

  const isJobWizardChecked: boolean = useTypedSelector(state => state.wizard.jobWizard.isChecked);

  const [deleteJobDocument] = useDeleteJobDocumentMutation({});
  const [updateJobDocument] = useUpdateJobDocumentMutation({});

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue('gray.600', 'white');
  const grayTextColor = useColorModeValue('gray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');
  const typeSelectionTitleColor = useColorModeValue('_brand.800', 'white');
  const typeSelectionBgColor = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const categoryTextColor = useColorModeValue('navy.750', 'whiteAlpha.900');
  const categorySelectedColor = useColorModeValue('green.500', 'green.500');

  const jobDocuments = useTypedSelector(state => selectJobDocuments(state, jobId));

  const updateDocument = async (dto: Partial<JobDocumentUpdateDto>, document: JobDocument) => {
    try {
      const result = await updateJobDocument({
        jobId,
        id: document.id,
        name: dto.name ?? document.name,
        type: dto.type ?? document.type,
      }).unwrap();

      if (result.status === DocumentStatus.NO_DATA_FOUND_ON_DOCUMENT || result.status === DocumentStatus.CREATED) {
        addDocumentsToPolling([result]);
      }
    } catch (e) {
      if ((e as { data: Error })?.data?.message === errorMEssageUnsupportedTypeModalTrigger) {
        onShowUserSelectedUnsupportedTypeModal(true);
      }
      console.error('Fail update file', document.id, e);
    }
  };

  const deleteFile = async (id: string) => {
    try {
      await deleteJobDocument({ id });
      const filteredDocs = uploadedDocs.filter((doc: JobDocument) => doc.id !== id);
      onUploadedDocs(filteredDocs);
    } catch (e) {
      console.error('Fail delete file', id, e);
    }
  };

  const [collapsed, setCollapsed] = React.useState<Record<string, boolean>>(() => {
    const initialState: Record<string, boolean> = {};
    jobDocuments.forEach(doc => {
      initialState[doc.id] = doc.type !== undefined && doc.type !== null;
    });
    return initialState;
  });

  const toggleAccordion = (docId: string) => {
    setCollapsed(prevCollapsed => ({
      ...prevCollapsed,
      [docId]: !prevCollapsed[docId],
    }));
  };

  React.useEffect(() => {
    const newCollapsed: Record<string, boolean> = {};
    jobDocuments.forEach(doc => {
      newCollapsed[doc.id] = collapsed[doc.id] ?? false;
    });
    setCollapsed(newCollapsed);
  }, [jobDocuments.length]);

  const columns = [
    columnHelper.accessor('status', {
      id: 'status',
      header: () => null,
      cell: info => <Flex align="center">{IconByStatus[info.getValue()]}</Flex>,
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Flex justifyContent="center" alignItems="center" gap="4px">
          <TableHeader>{t('pages.details.fileName')} </TableHeader>
          <ChevronDownIcon w="12px" h="24px" />
        </Flex>
      ),
      cell: info => {
        const document = info.table.getRow(info.row.id).original;

        const isFatalError = document.status === DocumentStatus.FATAL;
        const isUnacceptable = document.status === DocumentStatus.UNACCEPTABLE;
        const isChangeable = !document.isInitialized && !isFatalError && !isUnacceptable;

        if (!isChangeable) {
          return (
            <Flex alignItems="center">
              {isFatalError && (
                <Tag
                  color="red.400"
                  borderRadius="100px"
                  borderWidth="1px"
                  fontSize="10px"
                  borderColor="red.400"
                  bg="transparent"
                  p="5px"
                >
                  {t('pages.details.brokenFile')}
                </Tag>
              )}
              {isUnacceptable && (
                <Tag
                  color="orange.500"
                  borderRadius="100px"
                  borderWidth="1px"
                  fontSize="10px"
                  borderColor="orange.500"
                  bg="transparent"
                  p="5px"
                >
                  {t('pages.details.unacceptable')}
                </Tag>
              )}
              <Text p="16px" fontSize="14px" color={grayTextColor}>
                {info.getValue()}
              </Text>
            </Flex>
          );
        }

        return (
          <InputCell
            placeholder="Document name"
            initialValue={info.getValue()}
            onChange={value => updateDocument({ name: value }, document)}
          />
        );
      },
    }),
    columnHelper.accessor('id', {
      id: 'type',
      header: () => (
        <Flex justifyContent="center" alignItems="center" gap="4px">
          <TableHeader>{t('pages.details.category')}</TableHeader>
          <ChevronDownIcon w="12px" h="24px" />
        </Flex>
      ),
      cell: info => {
        const document = info.table.getRow(info.row.id).original;

        const categoryTitle =
          t(DocumentTranslatedCategoryByType[document.type as DocumentType]) || t('pages.details.categories.documents');

        return (
          <AppButton
            buttonType={AppButtonType.XSMALL}
            title={categoryTitle}
            colorSchemes={[AppButtonColorScheme.DISABLED]}
          />
        );
      },
    }),
    columnHelper.accessor('id', {
      id: 'action',
      header: () => (
        <Flex justifyContent="center" alignItems="center" gap="4px">
          <TableHeader> {t('pages.details.action')} </TableHeader>
          <ChevronDownIcon w="12px" h="24px" />
        </Flex>
      ),
      cell: info => (
        <Flex align="center" gap="16px">
          <AppButton
            buttonType={AppButtonType.XSMALL}
            title={t('pages.details.delete')}
            _hover={{ bg: 'red.400', color: 'white' }}
            onClick={() => deleteFile(info.getValue())}
            colorSchemes={[AppButtonColorScheme.DISABLED]}
            leftIcon={<RecycleBing boxSize={4} color={'red'} />}
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('id', {
      id: 'accordion',
      header: () => null,
      cell: info => {
        const document = info.table.getRow(info.row.id).original;

        const isCreated = document.status === DocumentStatus.CREATED;
        const isFatalError = document.status === DocumentStatus.FATAL;
        const isUnacceptable = document.status === DocumentStatus.UNACCEPTABLE;
        const isChangeable = !document.isInitialized && !isCreated && !isFatalError && !isUnacceptable;

        if (isChangeable && collapsed[document.id]) {
          return (
            <Flex align="center" color="gray.400" cursor="pointer" onClick={() => toggleAccordion(document.id)}>
              <ChevronDownIcon w="24px" h="48px" />
            </Flex>
          );
        }
        if (isChangeable && !collapsed[document.id]) {
          return (
            <Flex align="center" color="gray.400" cursor="pointer" onClick={() => toggleAccordion(document.id)}>
              <ChevronUpIcon w="24px" h="48px" />
            </Flex>
          );
        }
      },
    }),
  ];

  const table = useReactTable({
    data: jobDocuments,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Flex direction="column" gap="20px" bg={widgetBackgroundColor} overflowX={'auto'}>
      <Box position="relative" w="100%" minH="200px">
        {isJobWizardChecked && (
          <Flex direction={'column'} display={showHeader ? 'block' : 'none'}>
            <Flex direction={'row'} justify={'space-between'}>
              <Text color={textColor} fontWeight="700" fontSize={'18px'} width="fit-content">
                {t('pages.wizard.step3.categorizeFiles')}
              </Text>
            </Flex>

            <Flex width="fit-content">
              <Text color={grayTextColor} fontWeight="400" fontSize={'12px'}>
                {t('pages.wizard.step3.step3Description')}
              </Text>
            </Flex>
          </Flex>
        )}
        <br />
        <Table variant="simple" color="gray.500">
          <Thead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header, idx) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      p="0 8px 4px 8px"
                      w={ColumnsWidth[idx]}
                      minW={ColumnsMinWidth[idx]}
                      borderColor={borderColor}
                      cursor="pointer"
                      textTransform="capitalize"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          <Tbody>
            {table.getRowModel().rows.map(row => {
              const document = row.original;

              const isCreated = document.status === DocumentStatus.CREATED;
              const isFatalError = document.status === DocumentStatus.FATAL;
              const isUnacceptable = document.status === DocumentStatus.UNACCEPTABLE;
              const isChangeable = !document.isInitialized && !isCreated && !isFatalError && !isUnacceptable;

              return (
                <React.Fragment key={row.id}>
                  <Tr h="48px">
                    {row.getVisibleCells().map(cell => (
                      <Td key={cell.id} /* ... */>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                    ))}
                  </Tr>

                  {isChangeable && !collapsed[document.id] && (
                    <Tr bg={typeSelectionBgColor}>
                      <Td colSpan={columns.length} padding="24px">
                        <Flex gap="10px" wrap="wrap" direction="column">
                          <Flex gap="10px" wrap="wrap">
                            <Text color={typeSelectionTitleColor} fontSize="20px" fontWeight={700}>
                              {t('pages.details.coreDocuments')}
                            </Text>
                          </Flex>
                          <Flex gap="10px" wrap="wrap" p={4} direction="row">
                            {Object.values(DocumentTypesOrdered)
                              .filter(v => Object.values(DocumentType).includes(v as DocumentType))
                              .map(documentType => (
                                <AppButton
                                  key={`category-${documentType}`}
                                  buttonType={AppButtonType.PRIMARY}
                                  colorSchemes={[AppButtonColorScheme.TRANSPARENT]}
                                  bg={widgetBackgroundColor}
                                  leftIcon={
                                    documentType === document.type ? (
                                      <ApproveTick boxSize={6} />
                                    ) : (
                                      <DocumentOutFilledIcon boxSize={4} />
                                    )
                                  }
                                  onClick={() => {
                                    updateDocument({ type: documentType }, document);
                                    toggleAccordion(row.id);
                                  }}
                                  color={documentType === document.type ? categorySelectedColor : categoryTextColor}
                                >
                                  {t(DocumentTranslatedCategoryByType[documentType])}
                                </AppButton>
                              ))}
                          </Flex>
                          <Flex gap="10px" wrap="wrap">
                            <Text color={typeSelectionTitleColor} fontSize="20px" fontWeight={700}>
                              {t('pages.details.everythingElse')}
                            </Text>
                          </Flex>
                          <Flex gap="10px" wrap="wrap" p={4} direction="row">
                            {Object.values(DocumentTypesOrdered)
                              .filter(v => !Object.values(DocumentType).includes(v as DocumentType))
                              .map(documentType => (
                                <AppButton
                                  key={`category-${documentType}`}
                                  buttonType={AppButtonType.PRIMARY}
                                  colorSchemes={[AppButtonColorScheme.TRANSPARENT]}
                                  bg={widgetBackgroundColor}
                                  leftIcon={
                                    documentType === document.type ? (
                                      <ApproveTick boxSize={6} />
                                    ) : (
                                      <DocumentOutFilledIcon boxSize={4} />
                                    )
                                  }
                                  onClick={() => {
                                    updateDocument({ type: documentType }, document);
                                    toggleAccordion(document.id);
                                  }}
                                  color={documentType === document.type ? categorySelectedColor : categoryTextColor}
                                >
                                  {t(DocumentTranslatedCategoryByType[documentType])}
                                </AppButton>
                              ))}
                          </Flex>
                        </Flex>
                      </Td>
                    </Tr>
                  )}
                </React.Fragment>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Flex>
  );
}
