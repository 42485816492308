import { useTranslation } from 'react-i18next';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import Card from '../../../../components/card/Card';
import { Job, JobStatus } from 'api';
import { JobTemplatesWidget } from '../widgets/JobTemplatesWidget/JobTemplatesWidget';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';
import { Link, useNavigate } from 'react-router-dom';
import { JobMainInfoWidget } from '../components/job-main-info';
import { useJobForm } from '../widgets/JobDetailsWidget/useJobForm';
import { RelatedPartiesWidget } from '../widgets/AddContractsWidget/RelatedPartiesWidget';
import { useRelatedParties } from '../widgets/AddContractsWidget/useRelatedParties';
import { RelatedPartyEntityType, useInitJobPartiesMutation } from 'api/related-parties';
import { WaitPleasePopup } from 'components/waitPleasePopup/WaitPleasePopup';
import { JobErrorModal } from '../widgets/JobDetailsWidget/modals/JobErrorModal';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { VCAccordion, VCAccordionType } from '../../../../libs/ui/atoms/src/lib/accordion';
import { RightOfFirstRefusalModal } from '../../../../components/rightOfFirstRefusalModal/RightOfFirstRefusalModal';
import { CopyContractsTool } from '../../../../components/copyContractsTool/CopyContractsTool';
import { useTypedSelector } from '../../../../store';
import { JobSignButton } from '../../../../components/jobSignButton/JobSignButton';
import { AppButton, AppButtonType, AppButtonColorScheme } from '../../../../libs/ui/atoms/src/lib/appButton';
import { IMPICModal } from 'components/impic/IMPICModal';
import { ContractFieldsWidget } from '../widgets/contract-fields/ContractFieldsWidget';
import { debounce, max } from '../../../../utils';
import { useAppBreakpoint } from '../../../../theme/hooks';

export interface ContractsAndPartiesPageProps {
  job: Job;
  isLoadingSubmit: boolean;
  hideExtraData?: boolean;
  onSubmit: () => Promise<void>;
}

export function ContractsAndPartiesPage({
  job,
  isLoadingSubmit,
  hideExtraData = false,
  onSubmit,
}: ContractsAndPartiesPageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isHelpWidgetOpen = useTypedSelector(
    state => state.helpTools.selectedHelpTicketType && !state.helpTools.isHelpTicketPanelCollapsed,
  );

  const cardBackgroundColor = useColorModeValue('_gray.200', 'navy.900');

  const { jobName, onJobNameChanged, validateForm } = useJobForm(job.name);

  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [isJobErrorModalOpen, setIsJobErrorModalOpen] = useState(false);
  const [isContractsExpanded, setIsContractsExpanded] = useState(false);
  const [isExtraFieldsExpanded, setIsExtraFieldsExpanded] = useState(false);
  // Not source of true for contract fields validation
  const [isContractFieldsValid, setIsContractFieldsValid] = useState(false);

  const toolsAndContractsBoxRef = useRef<HTMLDivElement>(null);

  const [initJobParties, { isLoading: isLoadingInitJobParties }] = useInitJobPartiesMutation();
  const [isInited, setIsInited] = useState(false);

  const {
    sellers,
    buyers,
    onBuyerSearchChange,
    onSellerSearchChange,
    onDeleteItems,
    onResetToDefault,
    onCreate,
    onUpdate,
    onUpdateEntityTypes,
    loadParties,
  } = useRelatedParties(job.id, isInited);

  const init = useCallback(() => {
    if (isLoadingInitJobParties) return;

    initJobParties({ jobId: job.id })
      .unwrap()
      .then(() => {
        setIsInited(true);
        loadParties();
      })
      .catch(e => {
        console.error('initJobParties error', e);
      });
  }, [isLoadingInitJobParties, initJobParties, job.id, loadParties]);

  useEffect(() => {
    if (!isInited) {
      init();
    }
  }, [init, isInited]);

  const handleSubmit = async () => {
    if (validateForm()) {
      await onSubmit();
    }
  };

  useDidUpdateEffect(() => {
    if (job.status === JobStatus['Job is completed'] && job.statusMetadata?.status === 'ok') {
      navigate(`/job-result/${job.id}`);
    }
  }, [job.status]);

  const [prevJobStatus, setPrevJobStatus] = useState(job.status);
  useDidUpdateEffect(() => {
    if (prevJobStatus === JobStatus.Submitted && job.status !== JobStatus.Submitted) {
      if (job.statusMetadata?.status !== 'ok') {
        setIsJobErrorModalOpen(true);
      }
    }
    setPrevJobStatus(job.status);
  }, [job, prevJobStatus, setPrevJobStatus, setIsJobErrorModalOpen]);

  useEffect(() => {
    if (!toolsAndContractsBoxRef.current) return;
    const debouncedCallback = debounce(() => {
      forceUpdate();
    }, 100);
    const resizeObserver = new ResizeObserver(debouncedCallback);
    resizeObserver.observe(toolsAndContractsBoxRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const isAboveLg = useAppBreakpoint('lg');

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p={0}
        bg={cardBackgroundColor}
        overflowX={{ sm: 'auto', lg: 'hidden' }}
        position="static"
      >
        <Flex direction="column" gap="24px">
          <JobMainInfoWidget
            isReadOnly
            job={job}
            hideExtraData={hideExtraData}
            jobName={jobName}
            onNameUpdate={onJobNameChanged}
          />

          <Flex
            gap={'24px'}
            flexDirection={{
              base: 'column',
              '2xl': isHelpWidgetOpen ? 'column' : 'row',
              '3xl': 'row',
            }}
          >
            <RelatedPartiesWidget
              jobId={job.id}
              isShowCreateButton
              entityType={RelatedPartyEntityType.BUYER}
              title={t('relatedParties.buyers')}
              items={buyers}
              onSearch={onBuyerSearchChange}
              onDeleteItems={onDeleteItems}
              onResetToDefault={onResetToDefault}
              onUpdate={onUpdate}
              onUpdateEntityTypes={onUpdateEntityTypes}
              onCreate={onCreate}
              onLoad={loadParties}
            />
            <RelatedPartiesWidget
              jobId={job.id}
              entityType={RelatedPartyEntityType.SELLER}
              isShowCreateButton
              title={t('relatedParties.sellers')}
              items={sellers}
              onSearch={onSellerSearchChange}
              onDeleteItems={onDeleteItems}
              onResetToDefault={onResetToDefault}
              onUpdate={onUpdate}
              onUpdateEntityTypes={onUpdateEntityTypes}
              onCreate={onCreate}
              onLoad={loadParties}
            />
          </Flex>

          <Flex overflow={'hidden'}>
            {isAboveLg && (
              <ContractFieldsWidget
                jobId={job.id}
                isShowHeaderText={isContractsExpanded}
                isExpanded={isExtraFieldsExpanded}
                onToggleExpand={() => {
                  setIsExtraFieldsExpanded(!isExtraFieldsExpanded);
                  if (!isExtraFieldsExpanded) {
                    setIsContractsExpanded(true);
                  }
                }}
                onInvalidStatusChange={setIsContractFieldsValid}
                maxHeight={`${max(toolsAndContractsBoxRef.current?.offsetHeight ?? 0, 548) + 6}px`}
              />
            )}

            <Flex ref={toolsAndContractsBoxRef} w="100%" flex={1} direction={'column'} gap="24px">
              <Card flexDirection="column" w="100%" p="0" position="static">
                <VCAccordion
                  type={VCAccordionType.DEFAULT}
                  title={t('tools')}
                  bodyChildren={
                    <Flex p="0 24px 24px 24px" gap="8px" flexWrap={'wrap'}>
                      <>
                        <JobSignButton isAccordionItem />
                        <RightOfFirstRefusalModal jobId={job.id} jobMetadata={job.jobMetadata} />
                        <IMPICModal job={job} isLoadingInitJobParties={isLoadingInitJobParties} />
                        <CopyContractsTool job={job} />
                      </>
                    </Flex>
                  }
                />
              </Card>

              <JobTemplatesWidget
                jobId={job.id}
                title={t('contracts')}
                isExpanded={isContractsExpanded}
                onAccordionToggle={() => {
                  setIsContractsExpanded(!isContractsExpanded);
                  if (isContractsExpanded && isAboveLg) {
                    setIsExtraFieldsExpanded(false);
                  }
                }}
              />

              {!isAboveLg && (
                <ContractFieldsWidget
                  jobId={job.id}
                  isShowHeaderText={isExtraFieldsExpanded}
                  isExpanded={isExtraFieldsExpanded}
                  onToggleExpand={() => {
                    setIsExtraFieldsExpanded(!isExtraFieldsExpanded);
                    if (!isExtraFieldsExpanded && isAboveLg) {
                      setIsContractsExpanded(true);
                    }
                  }}
                  onInvalidStatusChange={setIsContractFieldsValid}
                />
              )}
            </Flex>
          </Flex>

          <Flex direction="column" gap="24px">
            <Flex gap="24px" justifyContent="end" direction={{ base: 'column', md: 'row' }}>
              <Link to={`/job-result/${job.id}`}>
                <AppButton
                  buttonType={AppButtonType.PRIMARY_MAIN}
                  title={t('pages.details.cancel')}
                  colorSchemes={[AppButtonColorScheme.DISABLED]}
                />
              </Link>
              <AppButton
                tooltip={!isContractFieldsValid ? t('pages.details.submitContractsInvalidTooltip') : ''}
                isDisabled={!isContractFieldsValid}
                title={t('pages.details.submitContracts')}
                buttonType={AppButtonType.PRIMARY_MAIN}
                onClick={handleSubmit}
                isLoading={isLoadingSubmit}
              />
            </Flex>
          </Flex>
        </Flex>
      </Card>

      <WaitPleasePopup job={job} />

      {job.statusMetadata?.traceId && (
        <JobErrorModal
          isOpen={isJobErrorModalOpen}
          onClose={() => setIsJobErrorModalOpen(false)}
          jobId={job.id}
          traceId={job.statusMetadata?.traceId}
        />
      )}
    </>
  );
}
