import { useTranslation } from 'react-i18next';
import { BoxProps, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from '../../../../../../components/card/Card';
import { Warning } from 'libs/ui/atoms/src';
import { RelatedParty, RelatedPartyDocumentExtractType, RelatedPartyEntityType } from 'api/related-parties';
import { VCDropzone, VCMimeTypes } from '../../../../../../libs/ui/atoms/src/lib/dropzone/Dropzone';
import { VCSelectButton } from '../../../../../../libs/ui/atoms/src/lib/button/SelectButton';
import { useExtractParty } from './useExtractParty';
import { useMemo } from 'react';
import { AppButton, AppButtonType, AppButtonColorScheme } from '../../../../../../libs/ui/atoms/src/lib/appButton';

const tByRelatedPartyDocumentExtractType: Record<RelatedPartyDocumentExtractType, string> = {
  [RelatedPartyDocumentExtractType.ID_DOCUMENT]: 'relatedParties.extract.idDocument',
  [RelatedPartyDocumentExtractType.EXISTING_CONTRACT]: 'relatedParties.extract.existingContract',
  [RelatedPartyDocumentExtractType.HERANCA]: 'relatedParties.extract.inheritance',
};

export interface PartyExtractProps extends BoxProps {
  jobId: string;
  entityType: RelatedPartyEntityType;
  relatedPartyToUpdate?: RelatedParty;
  parentRelatedParty?: RelatedParty;
  isDropzoneActivated?: boolean;

  onCancel: () => void;
  onSubmitted: () => void;
  onFilesDropped?: () => void;
}

export function PartyExtractWidget({
  jobId,
  entityType,
  relatedPartyToUpdate,
  parentRelatedParty,
  onCancel,
  onSubmitted,
  isDropzoneActivated,
  onFilesDropped,
  ...rest
}: PartyExtractProps) {
  const { t } = useTranslation();

  const {
    isLoading,
    isDisabled,
    files,
    documentsType,
    onDocumentTypeChange,
    onSubmit,
    onUploadFiles,
    onDeleteFile,
    onResetFiles,
  } = useExtractParty({
    jobId,
    entityType,
    parentRelatedPartyId: parentRelatedParty?.id,
    onFinished: onSubmitted,
    relatedPartyId: relatedPartyToUpdate?.id,
    isUpdateData: !!relatedPartyToUpdate,
    onFilesDropped,
  });

  const isFullSize = useMemo(() => isDropzoneActivated || !!files.length, [isDropzoneActivated, files.length]);

  const secondaryTitleTextColor = useColorModeValue('_gray.700', 'whiteAlpha.800');
  const secondaryTextColor = useColorModeValue('gray.500', 'white');
  const warningBg = useColorModeValue('white', 'navy.750');

  return (
    <Card
      flexDirection="column"
      zIndex={10}
      position={isFullSize ? 'absolute' : 'unset'}
      w="100%"
      h={isFullSize ? '100%' : '64px'}
      minH={isFullSize ? undefined : '32px'}
      p={isFullSize ? '0' : '0 24px'}
      overflowX={{ sm: 'auto', lg: 'hidden' }}
      maxH={'max(calc(100vh - 480px), 480px)'}
      animation={'fade-in .2s;'}
      {...rest}
    >
      <VCDropzone
        showFileSizeLimit
        isSmall={!isFullSize}
        title={
          entityType === RelatedPartyEntityType.BUYER
            ? t('relatedParties.extract.dropFilesHereToExtractPersonInformationBuyers')
            : t('relatedParties.extract.dropFilesHereToExtractPersonInformationSellers')
        }
        allowedFileTypes={[VCMimeTypes.JPEG, VCMimeTypes.PDF, VCMimeTypes.PNG]}
        files={files}
        onDrop={onUploadFiles}
        onDeleteFile={onDeleteFile}
        secondHeaderChildren={
          parentRelatedParty && (
            <Text fontSize={'14px'} fontWeight="semibold" lineHeight={'15px'} color={secondaryTitleTextColor}>
              {t('relatedParties.extract.youAreExtractingCompany', { name: parentRelatedParty?.name })}
            </Text>
          )
        }
        topChildren={
          isFullSize && (
            <Flex flexDirection={'column'} gap="4px" pt={'24px'}>
              <VCSelectButton<RelatedPartyDocumentExtractType>
                value={documentsType}
                items={Object.values(RelatedPartyDocumentExtractType)
                  .filter(i =>
                    parentRelatedParty || entityType === RelatedPartyEntityType.BUYER
                      ? i !== RelatedPartyDocumentExtractType.HERANCA
                      : true,
                  )
                  .map(value => ({
                    label: t(tByRelatedPartyDocumentExtractType[value]),
                    value,
                  }))}
                onSelect={onDocumentTypeChange}
              />
            </Flex>
          )
        }
        bottomChildren={
          isFullSize && (
            <Flex gap="24px" flexDirection={'column'} alignItems="center">
              <Flex
                w="fit-content"
                bg={warningBg}
                gap={'8px'}
                alignItems="center"
                justifyContent={'center'}
                p="8px 16px"
                borderRadius={'8px'}
                maxW={'100%'}
              >
                <Warning w="24px" h="24px" color={'red'} />
                <Text
                  w="360px"
                  maxW={{ base: '100%', sm: '100%', lg: '360px' }}
                  fontSize={'12px'}
                  fontWeight="normal"
                  lineHeight={'15px'}
                  color={secondaryTextColor}
                  overflowWrap="break-word"
                  flexWrap={'wrap'}
                  whiteSpace="normal"
                  align={'start'}
                >
                  {t('relatedParties.extract.pleaseMakeSureYouDoubleCheckTheData')}
                </Text>
              </Flex>

              <Flex gap="24px" justifyContent="center" onClick={e => e.stopPropagation()}>
                <AppButton
                  buttonType={AppButtonType.MAIN}
                  onClick={() => {
                    onCancel();
                    onResetFiles();
                  }}
                  title={t('pages.details.cancel')}
                  colorSchemes={[AppButtonColorScheme.DISABLED]}
                />
                <AppButton
                  buttonType={AppButtonType.MAIN}
                  onClick={onSubmit}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  title={t('relatedParties.extract.extract')}
                />
              </Flex>
            </Flex>
          )
        }
      />
    </Card>
  );
}
