import { Flex, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuDownload } from 'react-icons/lu';
import {
  EntityType,
  SigningDocumentType,
  SubmissionSigningStatus,
  UniversalDocumentStatus,
  useCreateUniversalDocumentMutation,
  useDeleteUniversalDocumentMutation,
  useReorderUniversalDocumentMutation,
  useUploadUniversalDocumentMutation,
} from '../../../../api';
import { SigningSingleResponse } from '../../../../api/signing';
import { FileCard } from '../../../../components/card/FileCard';
import { DocumentsViewer } from '../../../../components/documentsViewer/DocumentsViewer';
import { DocumentOutFilledIcon, ReloadIcon } from '../../../../components/icons/Icons';
import SortableList from '../../../../components/SortableList/SortableList';
import { AppButtonColorScheme, Download, RecycleBing } from '../../../../libs/ui/atoms/src';
import { VCDropzone, VCMimeTypes } from '../../../../libs/ui/atoms/src/lib/dropzone/Dropzone';
import { AppButton, AppButtonType } from '../../../../libs/ui/atoms/src/lib/appButton';
import { useAppToast } from '../../../../libs/ui/hooks';

const MAX_TOTAL_SIZE_MB = 9.5;
const MAX_TOTAL_SIZE_BYTES = MAX_TOTAL_SIZE_MB * 1024 * 1024;

const documentTypeKeys: Record<SigningDocumentType, string> = {
  [SigningDocumentType.DECLARATION]: 'certificate',
  [SigningDocumentType.INPUT]: '',
  [SigningDocumentType.PREPARED]: 'pages.signing.signaturePackStatusPendingSignatures',
  [SigningDocumentType.SIGNED]: 'pages.signing.signaturePackStatusSigned',
};

export interface SigningDetailsDocumentsWidgetProps {
  signing: SigningSingleResponse;
  onDocumentsChanged: () => void;
}

export const SigningDetailsDocumentsWidget = ({ signing, onDocumentsChanged }: SigningDetailsDocumentsWidgetProps) => {
  const { t } = useTranslation();
  const { showError, showApiError, showCatchedError } = useAppToast();

  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');
  const iconColor = useColorModeValue('navy.750', 'white');
  const cardColumns = useBreakpointValue({ base: 1, xxl: 2 });

  const [createDocument] = useCreateUniversalDocumentMutation();
  const [uploadDocument] = useUploadUniversalDocumentMutation();
  const [deleteDocument] = useDeleteUniversalDocumentMutation();
  const [reorderDocuments] = useReorderUniversalDocumentMutation();

  const handleDrop = useCallback(
    async (droppedFiles: File[]) => {
      try {
        const existingTotalSize =
          signing.documents?.reduce((acc, doc) => {
            return acc + (doc.metadata?.fileSize || 0);
          }, 0) || 0;

        const newFilesTotalSize = droppedFiles.reduce((acc, file) => acc + file.size, 0);

        if (existingTotalSize + newFilesTotalSize > MAX_TOTAL_SIZE_BYTES) {
          showError(
            t('pages.signing.filesSizeLimitExceeded', {
              size: MAX_TOTAL_SIZE_MB,
            }),
          );
          return;
        }

        await Promise.all(
          droppedFiles.map(async file => {
            const document = await createDocument({
              name: file.name,
              entityType: EntityType.SIGNING,
              entityId: signing.id,
            }).unwrap();

            const formData = new FormData();
            formData.set('inputFile', file);

            return uploadDocument({ id: document.id, formData }).unwrap();
          }),
        );
        onDocumentsChanged();
      } catch (e) {
        showApiError(e);
      }
    },
    [onDocumentsChanged, createDocument, signing.id, showError, signing.documents, t, showApiError, uploadDocument],
  );

  const reorder = useCallback(
    async (orderedIds: string[]) => {
      const newItems = await reorderDocuments({ orderedIds }).unwrap();
      await new Promise(resolve => setTimeout(resolve, 1000));
      onDocumentsChanged();
      return newItems.map(item => item.id);
    },
    [onDocumentsChanged, reorderDocuments],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await deleteDocument({ id }).unwrap();
      } catch (error) {
        showCatchedError(error);
      }
      onDocumentsChanged();
    },
    [deleteDocument, onDocumentsChanged, showCatchedError],
  );

  const documents = useMemo(() => {
    return [...(signing.documents ?? [])]
      .sort((a, b) => a.metadata?.orderIndex ?? 0 - (b?.metadata?.orderIndex ?? 0))
      .map((doc, idx) => ({
        orderIndex: doc.metadata?.orderIndex ?? idx + 1,
        id: doc.id,
        title: doc.name,
        iconChildren:
          doc.status === UniversalDocumentStatus.CREATED ? (
            <ReloadIcon />
          ) : (
            <DocumentOutFilledIcon width="24px" height="24px" />
          ),
        rightAction: (
          <Flex gap={'12px'}>
            <AppButton
              href={doc.url}
              download={doc.name}
              buttonType={AppButtonType.ICON}
              children={<LuDownload size={16} color={'blue.500'} />}
              _hover={{
                borderColor: 'blue.500',
                bg: 'blue.500',
                color: 'white',
              }}
              mr="5px"
            />

            {signing?.submission?.status !== SubmissionSigningStatus.PREPARING && (
              <AppButton
                buttonType={AppButtonType.ICON}
                onPointerDown={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleDelete(doc.id);
                }}
                colorSchemes={[AppButtonColorScheme.DANGER]}
                children={<RecycleBing boxSize={5} />}
              />
            )}
          </Flex>
        ),
      }));
  }, [handleDelete, signing.documents, signing?.submission?.status]);

  return (
    <>
      {!signing.submission && (
        <Flex p={`24px 24px 0px 24px`} direction="column" bg={widgetBackgroundColor}>
          <VCDropzone
            title={t('pages.signing.uploadFiles')}
            onDrop={handleDrop}
            allowedFileTypes={[VCMimeTypes.PDF]}
            dropzoneFlexProps={{ p: '16px 32px', borderRadius: '16px' }}
          />
        </Flex>
      )}

      {!signing.submission || signing?.submission?.status === SubmissionSigningStatus.PREPARING ? (
        <SortableList
          items={documents}
          onMove={reorder}
          isDisabled={signing?.submission?.status === SubmissionSigningStatus.PREPARING}
        />
      ) : (
        <DocumentsViewer
          disableScale
          compactView
          gridProps={{ columns: cardColumns, padding: '24px' }}
          documentCards={signing.documents.map((doc, i) => (
            <FileCard
              key={i}
              title={doc.name}
              icon={<DocumentOutFilledIcon width="24px" height="24px" color={iconColor} />}
              footer={
                <Flex align="center" justify="space-between" gap="8px">
                  <Text
                    fontSize={{ base: 'xs', md: 'sm' }}
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="normal"
                    color={iconColor}
                  >
                    {doc.metadata?.signingDocumentType
                      ? t(documentTypeKeys[doc.metadata?.signingDocumentType])
                      : t('documents')}
                  </Text>
                  <AppButton
                    href={doc.url}
                    download={doc.name}
                    buttonType={AppButtonType.PRIMARY}
                    leftIcon={<Download boxSize={4} />}
                    title={t('download')}
                  />
                </Flex>
              }
              cardProps={{ borderRadius: '15px' }}
            />
          ))}
        />
      )}
    </>
  );
};
