import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  Box,
  Text,
  useToast,
  useColorModeValue,
  Link,
  Flex,
} from '@chakra-ui/react';
import { environment } from 'environments/environment';
import { Copy, ErrorInSync } from 'libs/ui/atoms/src';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AppButton, AppButtonType } from '../../../../../../libs/ui/atoms/src/lib/appButton';

interface JobErrorModalProps {
  jobId: string;
  traceId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const JobErrorModal = ({ jobId, traceId, isOpen, onClose }: JobErrorModalProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  const linkBgColor = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
  const linkColor = useColorModeValue('navy.750', 'white');
  const contactSupportTextColor = useColorModeValue('navy.900', 'white');

  const copyUrlToClipboard = useCallback(async () => {
    const textToCopy = `${jobId}:${traceId}`;
    await navigator.clipboard.writeText(textToCopy);
    toast({ title: t('copiedToClipboard'), status: 'info', duration: 1500 });
  }, [jobId, traceId, toast]);

  return (
    <Modal size={'md'} onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="16" padding={'2'} paddingBottom="6">
        <ModalHeader>{t('error')}</ModalHeader>
        <ModalCloseButton top={'6'} right="4" />
        <ModalBody>
          <Flex flexDirection={'column'} gap={'24px'} alignItems={'center'}>
            <ErrorInSync w="64px" h="64px" />

            <Text fontSize="14px" textAlign="center">
              <Trans
                i18nKey="pages.details.contactSupport"
                components={{
                  emailLink: (
                    <Link
                      fontWeight={'bold'}
                      textDecoration={'underline'}
                      href={`mailto:${environment.contactEmail}`}
                      textColor={contactSupportTextColor}
                    ></Link>
                  ),
                }}
              ></Trans>
            </Text>

            <Box
              width={'100%'}
              borderRadius={'8'}
              bg={linkBgColor}
              paddingStart={'4'}
              paddingTop="2"
              paddingEnd="2"
              paddingBottom="2"
              gap={'4'}
              display="flex"
              alignItems={'center'}
              justifyContent="space-between"
            >
              <Text color={linkColor} wordBreak={'break-all'}>
                {jobId}:{traceId}
              </Text>
              <AppButton
                buttonType={AppButtonType.SECONDARY}
                onClick={copyUrlToClipboard}
                children={<Copy boxSize={4} />}
              />
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
