import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useContractFieldsForm } from './useContractFieldsForm';
import { ContractFieldsForm } from './ContractFieldsForm';
import { useTypedSelector } from '../../../../../store';
import { selectJob } from '../../../../../store/job';
import { useCallback, useEffect, useRef } from 'react';
import { ContractType } from '../../../../../api/template';
import { AppButton, AppButtonType, DocumentInfo, LowerLeft, UpperRightArrow } from '../../../../../libs/ui/atoms/src';
import { useDidUpdateEffect } from '../../../../../hooks/useDidUpdateEffect';
import { useUpdateContractFieldsMutation } from '../../../../../api';
import { useAppToast } from '../../../../../libs/ui/hooks';
import Card from '../../../../../components/card/Card';

import './contract-fields.styles.css';
import { useAppBreakpoint } from '../../../../../theme/hooks';
import { debounce } from '../../../../../utils';
import { ContractFields } from '../../../../../api/extra-data/contract-fields';
import { MaybeWrapAccordion } from '../JobTemplatesWidget/MaybeWrapAccordion';

interface ContractFieldsWidgetProps {
  jobId: string;
  isExpanded?: boolean;
  onToggleExpand?: () => void;
  isShowHeaderText?: boolean;
  maxHeight?: string;

  onInvalidStatusChange?: (isValid: boolean) => void;
}

export function ContractFieldsWidget({
  jobId,
  isShowHeaderText,
  isExpanded,
  onToggleExpand,
  maxHeight,
  onInvalidStatusChange,
}: ContractFieldsWidgetProps) {
  const { t } = useTranslation();
  const { showApiError, showError } = useAppToast();

  const { form, dispatch, validate, isValid, setIsValid } = useContractFieldsForm();

  const job = useTypedSelector(state => (jobId ? selectJob(state, jobId) : null));

  const [updateContractFields, { isLoading }] = useUpdateContractFieldsMutation();

  const bg = useColorModeValue('white', 'navy.800');
  const validBg = useColorModeValue('green.100', 'green.100');
  const invalidBg = useColorModeValue('red.50', 'red.100');
  const validBorderColor = useColorModeValue('green.400', 'green.400');
  const invalidBorderColor = useColorModeValue('red.500', 'red.500');

  const isShowCpcvFields = job?.templates?.some(e => e.contractType === ContractType.CPCV);
  const isShowRentalFields = job?.templates?.some(e => e.contractType === ContractType.RENTAL);

  const textCollapsedColor = useColorModeValue('_brand.800', '_brand.800');
  const textColor = useColorModeValue('_brand.800', 'white');

  const expandIconHover = useColorModeValue('_gray.200', 'navy.750');

  useEffect(() => {
    dispatch({
      type: 'setInitialState',
      value: { ...job?.extraDataContractFields?.data },
    });

    setIsValid(!!job?.extraDataContractFields?.isValid);
  }, []);

  const debouncedUpdate = useRef(
    debounce(async (updatedFormData: ContractFields) => {
      try {
        const result = await updateContractFields({ jobId, data: updatedFormData }).unwrap();
        setIsValid(result.isValid);
      } catch (error) {
        showApiError(error);
      }
    }, 300),
  ).current;

  const handleUpdateContractFields = useCallback(async () => {
    const validationMessages = validate();
    if (validationMessages.length) {
      setIsValid(false);
      showError(validationMessages.map(i => t(i)).join(', \n'));
      return;
    }

    debouncedUpdate(form);
  }, [debouncedUpdate, form, setIsValid, showError, t, validate]);

  useDidUpdateEffect(() => {
    handleUpdateContractFields();
  }, [form]);

  useEffect(() => {
    onInvalidStatusChange?.(isValid);
  }, [isValid, onInvalidStatusChange]);

  const isAboveLg = useAppBreakpoint('lg');

  if (!isShowCpcvFields && !isShowRentalFields) {
    return <></>;
  }

  if (!isAboveLg) {
    return (
      <Card
        pr={0}
        bg={isExpanded ? bg : isValid ? validBg : invalidBg}
        border={'1px solid'}
        borderColor={!isExpanded ? 'transparent' : isValid ? validBorderColor : invalidBorderColor}
      >
        <MaybeWrapAccordion
          isAccordion
          isExpanded={isExpanded}
          onToggle={onToggleExpand}
          buttonColor={isExpanded ? textColor : textCollapsedColor}
          buttonChildren={
            <Flex
              flex={1}
              alignItems="center"
              justifyContent={isExpanded || !isShowHeaderText ? '' : 'center'}
              gap={'16px'}
              overflow={'hidden'}
            >
              <DocumentInfo width={'16px'} height="20px" color={isExpanded ? textColor : textCollapsedColor} />
              <Text
                fontSize={'20px'}
                fontWeight="700"
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                color={isExpanded ? textColor : textCollapsedColor}
              >
                {t('contractFields.extraInformation')}
              </Text>
            </Flex>
          }
        >
          <Box overflow={'auto'} maxHeight={`500px`} mt={'16px'}>
            <ContractFieldsForm
              form={form}
              dispatch={dispatch}
              isShowCpcvFields={isShowCpcvFields}
              isShowRentalFields={isShowRentalFields}
            />
          </Box>
        </MaybeWrapAccordion>
      </Card>
    );
  }

  return (
    <Card
      flex={1}
      display={'flex'}
      gap={'16px'}
      p="23px 0 15px 23px"
      mr={'24px'}
      flexDir="column"
      minW={isExpanded && isAboveLg ? '300px' : '72px'}
      maxW={isExpanded && isAboveLg ? '450px' : '72px'}
      overflow={'hidden'}
      border={'1px solid'}
      borderColor={!isExpanded ? 'transparent' : isValid ? validBorderColor : invalidBorderColor}
      bg={isExpanded ? bg : isValid ? validBg : invalidBg}
    >
      <Flex flex="1" mr="20px" gap={'16px'} direction={isExpanded ? 'row' : 'column-reverse'} overflow={'hidden'}>
        <Flex
          flex={1}
          alignItems="center"
          justifyContent={isExpanded || !isShowHeaderText ? '' : 'center'}
          gap={'16px'}
          direction={isExpanded ? 'row' : 'column-reverse'}
          overflow={'hidden'}
          mb={isExpanded ? '0' : '8px'}
        >
          <DocumentInfo
            width={'16px'}
            height="20px"
            color={isExpanded ? textColor : textCollapsedColor}
            transform={isExpanded ? '' : 'rotate(-90deg)'}
          />

          {isShowHeaderText && (
            <Text
              fontSize={'20px'}
              fontWeight="700"
              className={isExpanded ? '' : 'text-orientation-left-down-to-top'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              color={isExpanded ? textColor : textCollapsedColor}
            >
              {t('contractFields.extraInformation')}
            </Text>
          )}
        </Flex>

        <AppButton
          tooltip={t(isExpanded ? 'collapse' : 'expand')}
          buttonType={AppButtonType.ICON}
          color={isExpanded ? textColor : textCollapsedColor}
          onClick={onToggleExpand}
          _hover={{ bg: expandIconHover }}
        >
          {isExpanded ? <LowerLeft /> : <UpperRightArrow w="24px" h={'24px'} />}
        </AppButton>
      </Flex>

      {isExpanded && isAboveLg && (
        <Box overflow={'auto'} maxHeight={`calc(${maxHeight} - 94px)`}>
          <ContractFieldsForm
            form={form}
            dispatch={dispatch}
            isShowCpcvFields={isShowCpcvFields}
            isShowRentalFields={isShowRentalFields}
          />
        </Box>
      )}
    </Card>
  );
}
