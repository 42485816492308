import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';
import { useColorMode, useColorModeValue } from '@chakra-ui/system';
import { Flex } from '@chakra-ui/react';
import { Cancel } from '../icons/icons';
import { AppButton, AppButtonType } from '../appButton';

export interface VCDatePickerProps {
  isNotInModal?: boolean;
  isInWidget?: boolean;
  isDisabled?: boolean;
  placeholder: string;
  selectedValue?: Date;
  onChange: (date: Date | null, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
  filterDate?: (date: Date) => boolean;
  portalId?: string;
  onBlur?: () => void;
  minDate?: Date;
  maxDate?: Date;
  isInvalid?: boolean;
}

/**
 * VCDatePicker component renders a styled Datepicker using Chakra UI, with support for light and dark modes.
 * @param {Object} props - Component props.
 * @param {string} props.placeholder - Placeholder text for the Datepicker input field.
 * @param {Date | null} props.selectedValue - Currently selected date value.
 * @param {function} props.onChange - Callback function triggered on date selection change.
 * @param {function} props.filterDate - Function to filter selectable dates.
 * @param {string} props.portalId - ID of the portal element where the Datepicker calendar is appended. It's needed for correct
 * scrolling and positioning. Also note that portalId should start with 'chakra-modal-' prefix to work properly
 * @returns {JSX.Element} Rendered VCDatePicker component.
 */
export const VCDatePicker = ({
  isNotInModal,
  isInWidget,
  isDisabled,
  placeholder,
  selectedValue,
  onChange,
  filterDate,
  portalId,
  onBlur,
  minDate,
  maxDate,
  isInvalid,
}: VCDatePickerProps) => {
  const { colorMode } = useColorMode();
  // TODO: add colors to colors-schema
  const borderColor = useColorModeValue('gray.200', 'rgba(255, 255, 255, 0.16)');
  const borderColorFocus = useColorModeValue('blue.500', '#63b3ed');
  const borderColorHover = useColorModeValue('gray.300', 'rgba(255, 255, 255, 0.24)');
  const errorBorderColor = useColorModeValue('red.500', 'red.500');
  const textColor = useColorModeValue('brand.800', 'white');
  const cancelHoverColor = useColorModeValue('_gray.200', 'navy.750');

  return (
    <Flex
      height={'50px'}
      borderWidth="1px"
      borderRadius="6px"
      width="100%"
      alignItems={'center'}
      borderColor={isInvalid ? errorBorderColor : borderColor}
      _hover={{ borderColor: isInvalid ? errorBorderColor : borderColorHover }}
      _focusWithin={{
        borderColor: isInvalid ? errorBorderColor : borderColorFocus,
        boxShadow: colorMode === 'light' ? '0 0 0 1px #3965FF' : '0 0 0 1px #63b3ed',
      }}
      transition={'all 0.2s'}
    >
      <DatePicker
        readOnly={isDisabled}
        placeholderText={placeholder}
        onChange={onChange}
        selected={selectedValue}
        filterDate={filterDate}
        portalId={portalId}
        className={`vc-react-datepicker-input ${colorMode === 'light' ? 'vc-datepicker-light' : 'vc-datepicker-dark'} ${
          isNotInModal ? 'vc-datepicker-not-in-modal' : ''
        } ${isInWidget ? 'vc-datepicker-in-widget' : ''}`}
        calendarClassName={`vc-react-datepicker-calendar ${
          colorMode === 'light' ? 'vc-datepicker-light' : 'vc-datepicker-dark'
        }`}
        wrapperClassName="vc-react-datepicker-wrapper"
        calendarStartDay={1}
        dateFormat="dd/MM/yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        minDate={minDate}
        maxDate={maxDate}
        onBlur={onBlur}
      />

      {!isDisabled && selectedValue && (
        <AppButton
          buttonType={AppButtonType.ICON}
          children={<Cancel color={textColor} w="12px" h="12px" />}
          _hover={{ bg: cancelHoverColor }}
          mr="5px"
          onClick={() => onChange(null)}
        />
      )}
    </Flex>
  );
};
