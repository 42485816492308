import { useCallback } from 'react';
import { Box, Flex, Text, Switch, StyleProps, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { GoPlus } from 'react-icons/go';
import { t } from 'i18next';
import { SignerItem } from './signer-item';
import { SignerFormState } from '../signer/useSignerForm';
import { SigningLanguage, Signing, signingDefaultLanguage } from '../../../../api/signing';
import { Signer, SignerUpdateDto } from '../../../../api/signer';
import { AppButton, AppButtonType } from '../../../../libs/ui/atoms/src/lib/appButton';
import { RadioButton } from '../../../../libs/ui/atoms/src/lib/radiobutton/RadioButton';
import { UniversalSortableList } from '../../../../components/GenericSortableList/universal-sortable-list';
import { useAppToast } from '../../../../libs/ui/hooks';

export interface SignersWidgetProps extends StyleProps {
  isReadonly?: boolean;
  isInConfirmView?: boolean;
  isNewSignerCreating?: boolean;
  signing: Signing;
  isSerial?: boolean;
  language?: SigningLanguage;
  onSerialToggle?: (value: boolean) => void;
  onLanguageChange?: (value: SigningLanguage) => void;
  isLoading?: boolean;
  signers?: Signer[];
  onCreate?: (data: SignerFormState) => Promise<void>;
  onUpdate?: (dto: SignerUpdateDto) => Promise<void>;
  onDelete?: (id: string) => Promise<void>;
  onReorder?: (orderedIds: string[]) => Promise<void>;
  onSignerCreateClick?: () => void;
  onSignerDeleted?: (id?: string) => void;
  onSignerCreated?: () => void;
  onSignerUpdated?: (id?: string) => void;
}

export const SignersWidget = ({
  isReadonly,
  isInConfirmView,
  isNewSignerCreating,
  signing,
  signers,
  isSerial,
  language,
  onSerialToggle,
  onLanguageChange,
  isLoading,
  onCreate,
  onUpdate,
  onDelete,
  onReorder,

  onSignerCreateClick,
  onSignerDeleted,
  onSignerCreated,
  onSignerUpdated,
  ...style
}: SignersWidgetProps) => {
  const { showSuccess, showApiError } = useAppToast();

  const handleCreate = useCallback(
    async (data: SignerFormState) => {
      try {
        await onCreate?.(data);
        onSignerCreated?.();
        showSuccess(t('createdSuccessfully'));
      } catch (error) {
        showApiError(error);
      }
    },
    [onCreate, onSignerCreated, showSuccess, showApiError],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await onDelete?.(id);
        onSignerDeleted?.(id);
      } catch (error) {
        showApiError(error);
      }
    },
    [onDelete, onSignerDeleted, showApiError],
  );

  const handleMove = async (orderedIds: string[]) => {
    try {
      await onReorder?.(orderedIds);
      return orderedIds;
    } catch (error) {
      showApiError(error);
      return signers?.map(signer => signer.id) || [];
    }
  };

  const lgTextColor = useColorModeValue('brand.800', 'white');
  const mdTextColor = useColorModeValue('blue.900', 'white');
  const mdTextColorDisabled = useColorModeValue('gray.400', 'whiteAlpha.400');

  if (isLoading) {
    return <Box>...{t('loading')}</Box>;
  }

  const LANGUAGE_ITEMS = [
    { label: 'EN', value: SigningLanguage.EN },
    { label: 'PT', value: SigningLanguage.PT },
  ];

  const isDisabled = isReadonly || !!signing?.submission;

  return (
    <Card flexDirection="column" w="100%" p="24px" overflowX={{ sm: 'hidden', lg: 'hidden' }} {...style}>
      <Flex justifyContent="space-between" alignItems="center" mb="24px">
        <Flex alignItems="center">
          <Text fontWeight="bold" fontSize="20px" lineHeight="26px" color={lgTextColor}>
            {t('signer.signers')}
          </Text>
        </Flex>

        <Flex alignItems="center" justifyContent="flex-start">
          <Flex alignItems="center" ml="24px">
            <RadioButton
              fontWeight="bold"
              items={LANGUAGE_ITEMS}
              value={language || signingDefaultLanguage}
              onChange={value => onLanguageChange?.(value as SigningLanguage)}
              isDisabled={isDisabled}
            />
          </Flex>
          <Flex alignItems="center" ml="24px">
            <Text
              fontWeight="bold"
              fontSize="14px"
              lineHeight="24px"
              color={isDisabled ? mdTextColorDisabled : mdTextColor}
              mr="8px"
            >
              {t('pages.signing.serialSigning')}
            </Text>
            <Switch
              size="md"
              isChecked={isSerial}
              onChange={e => onSerialToggle?.(e.target.checked)}
              isDisabled={isDisabled}
            />
          </Flex>
        </Flex>
      </Flex>

      <UniversalSortableList
        items={signers || []}
        onMove={handleMove}
        renderItem={(signer, idx) => (
          <SignerItem
            isInConfirmView={isInConfirmView}
            key={signer.id}
            signer={signer}
            isDisabled={!!signing?.submission || isReadonly}
            onUpdate={onUpdate}
            onDelete={() => handleDelete(signer.id)}
            isShowBorderBottom={signers && (idx !== signers.length - 1 || isNewSignerCreating)}
            signingStatus={signing.submission?.status}
          />
        )}
        extraItem={
          isNewSignerCreating && (
            <SignerItem
              onCreate={handleCreate}
              onDelete={() => onSignerDeleted?.()}
              isDisabled={false}
              isShowBorderBottom={false}
            />
          )
        }
      />

      {!isReadonly && !signing?.submission && (
        <AppButton
          buttonType={AppButtonType.PRIMARY}
          leftIcon={<GoPlus size={'20'} />}
          isDisabled={isNewSignerCreating}
          w="fit-content"
          mt={!signers?.length && !isNewSignerCreating ? 0 : '16px'}
          onClick={() => !isNewSignerCreating && onSignerCreateClick?.()}
          title={t('signer.addNewSigner')}
        />
      )}
    </Card>
  );
};
