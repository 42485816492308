import { FormControl, FormLabel, StyleProps } from '@chakra-ui/react';
import { RadioButton, RadioButtonItem, RadioButtonProps } from './RadioButton';

export interface RadioButtonFieldProps<T extends string = string>
  extends Omit<RadioButtonProps<T>, 'items' | 'value' | 'onChange'> {
  label: string;
  items: RadioButtonItem<T>[];
  value?: T;
  onChange: (value: T) => void;
}

export const RadioButtonField = <T extends string>({
  label,
  items,
  value,
  onChange,
  isDisabled,
  ...styles
}: RadioButtonFieldProps<T>) => {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <RadioButton items={items} value={value} onChange={onChange} isDisabled={isDisabled} {...styles} />
    </FormControl>
  );
};
