import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNifValid } from '../../../../../../utils';

import { FormInputField, FormInputFieldProps } from './form-input-field';

export interface NIFInputFieldProps extends FormInputFieldProps {
  onBlurOrValid?: (value: string) => void;
}

export function NIFInputField({
  value,
  onBlurOrValid,
  onChange,
  onBlur,
  isRequired,
  isInvalid,
  ...props
}: NIFInputFieldProps) {
  const [localValue, setLocalValue] = useState(value);

  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const handleChange = (value: string) => {
    setLocalValue(value);
    onChange?.(value);
    const isValid = value ? isNifValid(value) : !isRequired;
    setError(isValid ? null : t('nifIsNotValid'));
    if (isValid) {
      onBlurOrValid?.(value);
    }
  };

  const handleBlur = (value: string) => {
    onBlur?.(value);
    onBlurOrValid?.(value);
  };

  return (
    <FormInputField
      {...props}
      isRequired={isRequired}
      value={localValue}
      onChange={handleChange}
      onBlur={handleBlur}
      isInvalid={!!isInvalid || !!error?.length}
      errorMessage={error}
    />
  );
}
