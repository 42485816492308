import { RightOfFirstRefusalDensity, RightOfFirstRefusalItemsMeasure, RightOfFirstRefusalTypeOfBusiness } from 'api';

export const tByRightOfFirstRefusalTypeOfBusiness: Record<RightOfFirstRefusalTypeOfBusiness, string> = {
  [RightOfFirstRefusalTypeOfBusiness.BUY_AND_SELL]: 'rightOfFirstRefusal.typeOfBusiness.buyAndSell',
  [RightOfFirstRefusalTypeOfBusiness.PAYMENT_IN_PAYMENT]: 'rightOfFirstRefusal.typeOfBusiness.paymentInPayment',
};

export const tByRightOfFirstRefusalDensity: Record<RightOfFirstRefusalDensity, string> = {
  [RightOfFirstRefusalDensity.BUSINESS]: 'rightOfFirstRefusal.destiny.business',
  [RightOfFirstRefusalDensity.HOUSING]: 'rightOfFirstRefusal.destiny.housing',
  [RightOfFirstRefusalDensity.INDUSTRY]: 'rightOfFirstRefusal.destiny.industry',
  [RightOfFirstRefusalDensity.OTHER]: 'rightOfFirstRefusal.destiny.other',
};

export const tByRightOfFirstRefusalItemsMeasure: Record<RightOfFirstRefusalItemsMeasure, string> = {
  [RightOfFirstRefusalItemsMeasure.ACRE]: 'rightOfFirstRefusal.itemsMeasure.acre',
  [RightOfFirstRefusalItemsMeasure.METERS_SQUARE]: 'rightOfFirstRefusal.itemsMeasure.metersSquare',
};
