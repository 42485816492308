import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/query';
import { api } from './api';
import { Job } from './job.private';
import { Signer } from './signer';
import { EntityType, Submission, SubmissionSigningStatus } from './submission';
import { UniversalDocument, UniversalDocumentSigningMetadata } from './universal-document';

export enum SigningType {
  SMS = 'SMS',
  MOBILE_KEY_OR_CITIZEN_CARD = 'MOBILE_KEY_OR_CITIZEN_CARD',
}

export enum SigningLanguage {
  EN = 'EN',
  PT = 'PT',
}

export const signingDefaultLanguage = SigningLanguage.PT;

export type SigningEntityMap = {
  [EntityType.JOB]: Job;
};

export interface Signing {
  id: string;
  name?: string;
  signingType: SigningType;
  resultArchiveFile?: File;
  entityId?: string;
  entityType?: EntityType;
  organizationId?: string;
  submission?: Submission<SubmissionSigningStatus>;
  isSerial?: boolean;
  language?: SigningLanguage;
  isSignedDocumentBilled?: boolean;

  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
}

export type SigningListResponse<T extends EntityType = EntityType> = Signing & {
  entity?: T extends keyof SigningEntityMap ? SigningEntityMap[T] : undefined;
};

export type SigningSingleResponse<T extends EntityType = EntityType> = Signing & {
  entity?: T extends keyof SigningEntityMap ? SigningEntityMap[T] : undefined;
  signers: Signer[];
  documents: UniversalDocument<UniversalDocumentSigningMetadata>[];
};

export interface SigningPagedListResponse {
  data: SigningListResponse[];
  totalCount: number;
  pageCount: number;
}

export interface SigningGetListDto {
  skip?: number;
  take?: number;
  orderBy?: string;
  search?: string;
  orderDirection?: 'ASC' | 'DESC';
  signingType?: SigningType;
  lastUpdatedAt?: Date;
  status?: SubmissionSigningStatus;
}

export interface SigningCreateDto {
  entityId?: string;
  entityType?: EntityType;
  name?: string;
  language?: SigningLanguage;
}

export interface SigningUpdateDto {
  id: string;
  name?: string;
  isSerial?: boolean;
  language?: SigningLanguage;
}

export const signingApi = api.injectEndpoints({
  endpoints: build => ({
    getSignings: build.query<SigningPagedListResponse, SigningGetListDto>({
      query: ({ skip, take, orderBy, orderDirection, search, signingType, lastUpdatedAt, status }) => ({
        url: '/signing',
        params: { skip, take, orderBy, orderDirection, search, signingType, lastUpdatedAt, status },
      }),
      serializeQueryArgs: ({ queryArgs: { skip, take, ...queryArgs }, endpointName, endpointDefinition }) =>
        defaultSerializeQueryArgs({ queryArgs, endpointName, endpointDefinition }),
    }),
    getSigning: build.query<SigningSingleResponse, { signingId: string }>({
      query: ({ signingId }) => ({ url: `/signing/${signingId}` }),
    }),
    createSigning: build.mutation<SigningSingleResponse, SigningCreateDto>({
      query: dto => ({ url: '/signing', body: dto, method: 'POST' }),
    }),
    updateSigning: build.mutation<SigningSingleResponse, SigningUpdateDto>({
      query: dto => ({ url: '/signing', body: dto, method: 'PUT' }),
    }),
    deleteSigning: build.mutation<SigningSingleResponse, { id: string }>({
      query: ({ id }) => ({ url: `/signing/${id}`, method: 'DELETE' }),
    }),
    submitSigning: build.mutation<SigningSingleResponse, { id: string }>({
      query: dto => ({ url: '/signing/submit', body: dto, method: 'POST' }),
    }),
    sendSigning: build.mutation<SigningSingleResponse, { id: string }>({
      query: dto => ({ url: '/signing/send', body: dto, method: 'POST' }),
    }),
    abandonPreparationSigning: build.mutation<SigningSingleResponse, { id: string }>({
      query: dto => ({ url: '/signing/abandonPreparation', body: dto, method: 'POST' }),
    }),
    notifySigners: build.mutation<void, { signerIds: string[] }>({
      query: dto => ({ url: '/signing/notify-signers', body: dto, method: 'POST' }),
    }),
    cloneSigning: build.mutation<SigningSingleResponse, { id: string }>({
      query: ({ id }) => ({ url: `/signing/clone/${id}`, method: 'POST' }),
    }),
  }),
});

export const {
  useGetSigningsQuery,
  useLazyGetSigningsQuery,
  useGetSigningQuery,
  useLazyGetSigningQuery,
  useCreateSigningMutation,
  useUpdateSigningMutation,
  useDeleteSigningMutation,
  useSubmitSigningMutation,
  useSendSigningMutation,
  useAbandonPreparationSigningMutation,
  useNotifySignersMutation,
  useCloneSigningMutation,
} = signingApi;
