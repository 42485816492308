import { FormControl, FormLabel, Text } from '@chakra-ui/react';
import { VCDatePicker } from '../datePicker/DatePicker';

export interface DatePickerFieldProps {
  isNotInModal?: boolean;
  isInWidget?: boolean;
  value?: Date | null;
  label: string;
  onChange: (date: Date | null, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
  onBlur?: () => void;

  isReadonly?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  minDate?: Date;
  maxDate?: Date;
  filterDate?: (date: Date) => boolean;

  portalId?: string;
}

export function DatePickerField({
  isNotInModal,
  isInWidget,
  value,
  label,
  onChange,
  onBlur,
  isReadonly,
  isInvalid,
  isRequired,
  minDate,
  maxDate,
  filterDate,
  portalId,
}: DatePickerFieldProps) {
  return (
    <FormControl maxW={'100%'} overflow="hidden" p="1px">
      <FormLabel mb="4px" maxW={'100%'} overflow="hidden" display={'flex'} title={label}>
        <Text className="no-text-wrap" as="span" textOverflow={'ellipsis'} overflow="hidden">
          {label}
        </Text>
        {isRequired && (
          <Text as={'span'} color={'red'}>
            {' *'}
          </Text>
        )}
      </FormLabel>

      <VCDatePicker
        isInvalid={isInvalid}
        isNotInModal={isNotInModal}
        isInWidget={isInWidget}
        selectedValue={value ? new Date(value) : undefined}
        isDisabled={isReadonly}
        placeholder={label}
        onChange={onChange}
        onBlur={onBlur}
        minDate={minDate}
        maxDate={maxDate}
        filterDate={filterDate}
        portalId={portalId}
      />
    </FormControl>
  );
}
