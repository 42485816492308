import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Spinner, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import {
  DocumentType,
  JobStatus,
  JobWithDocumentsAndResult,
  useGetJobPrivateQuery,
  useLazyGetJobPrivateQuery,
  useSubmitContractsJobMutation,
} from '../../../api';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { jobSelected, jobUnselected, selectJob, selectJobDocuments } from '../../../store/job';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';
import { useTranslation } from 'react-i18next';
import { ContractsAndPartiesPage } from './pages/ContractsAndPartiesPage';
import { ErrorDisplay } from 'libs/ui/atoms/src/lib/errorDisplay/ErrorDisplay';

export function AddContractsPage() {
  const { jobId } = useParams<{ jobId: string }>();
  const toast = useToast();
  const { t } = useTranslation();
  const { isLoading, error, data } = useGetJobPrivateQuery(
    { jobId: jobId ?? '' },
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      refetchOnFocus: true,
    },
  );
  const [submitJob, { isLoading: isLoadingSubmit, error: errorSubmit }] = useSubmitContractsJobMutation();
  const dispatch = useAppDispatch();

  const job = useTypedSelector(state => (jobId ? selectJob(state, jobId) : null));
  const jobDocuments = useTypedSelector(state => selectJobDocuments(state, jobId ?? ''));
  const certidaoPermanenteDoc = jobDocuments.find(i => i.type === DocumentType.CERTIDAO_PERMANENTE);

  const [getJob] = useLazyGetJobPrivateQuery({ pollingInterval: job?.status === JobStatus.Submitted ? 700 : 0 });

  useEffect(() => {
    if (!job) {
      return;
    }

    dispatch(jobSelected({ job: job as JobWithDocumentsAndResult }));

    return () => {
      dispatch(jobUnselected());
    };
  }, [job, dispatch]);

  const handleSubmit = useCallback(async () => {
    console.debug('Handle submit');
    if (!job || !jobId) {
      console.error('Invalid application state! Not found job to submit.');
      return;
    }

    if (job.templates?.length === 0) {
      toast({ title: t('pages.details.noContractsSelected'), status: 'error', duration: 5000 });
      return;
    }

    await submitJob({ jobId });

    getJob({ jobId });
  }, [getJob, job, jobId, submitJob]);

  useEffect(() => {
    if (!jobId) {
      return;
    }
    getJob({ jobId });
  }, [certidaoPermanenteDoc?.id, certidaoPermanenteDoc?.status, jobId, getJob]);

  useDidUpdateEffect(() => {
    if (errorSubmit) {
      const message = (errorSubmit as any)?.data?.message ? (errorSubmit as any)?.data?.message : '';
      toast({ title: t(message), status: 'error', duration: 5000 });
    }
  }, [errorSubmit]);

  if (isLoading && !job) {
    return (
      <Flex align="center" justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  if (error || !job) {
    return <ErrorDisplay error={error} hasJobCheck job={job} />;
  }

  return <ContractsAndPartiesPage job={job} onSubmit={handleSubmit} isLoadingSubmit={isLoadingSubmit} />;
}
