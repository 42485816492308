import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectionMenu, SelectionMenuOption } from 'libs/ui/atoms/src';
import { useAppDispatch, useTypedSelector } from 'store';
import { setOrganizationId } from 'store/auth';
import { useNavigate } from 'react-router-dom';

export const OrganizationSelector = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const personalMode = useTypedSelector(state => state.auth.user?.isPersonalEnabled);
  const organizations = useTypedSelector(state => state.auth.user?.organizations);
  const organizationId = useTypedSelector(state => state.auth.organizationId);

  const options = useMemo<SelectionMenuOption[]>(
    () => [
      ...(personalMode !== false ? [{ title: t('personal'), value: null }] : []),
      ...(organizations?.map(org => ({
        title: org.name,
        value: org.id,
      })) || []),
    ],
    [organizations, organizationId],
  );

  const changeOrganization = (organizationId: string) => {
    dispatch(setOrganizationId({ organizationId }));
    navigate('/home');
  };

  return <SelectionMenu value={organizationId} options={options} onChange={changeOrganization} />;
};
