import {
  CivilStatus,
  MarriageRegime,
  RelatedPartyAge,
  RelatedPartyCreateDto,
  RelatedPartyIDDocumentType,
  RelatedPartyRelationType,
} from 'api/related-parties';
import { useCallback, useReducer } from 'react';
import { isEnum } from 'utils/is-enum';
import { isEmailValid, isNifValid, isPhoneValid, isPostalCodeValid } from 'utils/validators';

type SetInitialStateAction = { type: 'setInitialState'; value: RelatedPartyFormState };
type SetRelationTypeAction = { type: 'setRelationType'; value: RelatedPartyRelationType };
type SetNameAction = { type: 'setName'; value: string };
type SetEmailAction = { type: 'setEmail'; value: string };
type SetPhoneAction = { type: 'setPhone'; value: string };
type SetNifNipsAction = { type: 'setNifNips'; value: string };
type SetAddressAction = { type: 'setAddress'; value: string };
type SetCivilStatusAction = { type: 'setCivilStatus'; value: CivilStatus };
type SetMarriageRegimeAction = { type: 'setMarriageRegime'; value: MarriageRegime };
type SetMarriedToAction = { type: 'setMarriedTo'; value: string };
type SetAgeAction = { type: 'setAge'; value: RelatedPartyAge };
type SetNacionalidadeAction = { type: 'setNacionalidade'; value: string };
type SetIsBornInPortugalAction = { type: 'setIsBornInPortugal'; value: boolean };
type SetPlaceOfBirthFreguesiaAction = { type: 'setPlaceOfBirthFreguesia'; value: string };
type SetPlaceOfBirthConcelhoAction = { type: 'setPlaceOfBirthConcelho'; value: string };
type setPlaceOfBirthAction = { type: 'setPlaceOfBirth'; value: string };
type SetIdDocumentTypeAction = { type: 'setIdDocumentType'; value: RelatedPartyIDDocumentType };
type SetIdDocumentNumberAction = { type: 'setIdDocumentNumber'; value: string };
type SetIdDocumentIssuanceAuthorityAction = { type: 'setIdDocumentIssuanceAuthority'; value: string };
type SetIdDocumentIssueDateAction = { type: 'setIdDocumentIssueDate'; value: Date };
type SetIdDocumentExpiryDateAction = { type: 'setIdDocumentExpiryDate'; value: Date };
type SetCorpAddressAction = { type: 'setCorpAddress'; value: string };
type SetCorpAddressParishAction = { type: 'setCorpAddressParish'; value: string };
type SetCorpAddressMunicipalityAction = { type: 'setCorpAddressMunicipality'; value: string };
type SetCorpAddressDistrictAction = { type: 'setCorpAddressDistrict'; value: string };
type SetCorpCapitalAction = { type: 'setCorpCapital'; value: string };
type SetCorpAddressPostalCode = { type: 'setCorpAddressPostalCode'; value: string };
type SetCorpRepAuthorityAction = { type: 'setCorpRepAuthority'; value: string };
type SetIsFiadorAction = { type: 'setIsFiador'; value: boolean };
type setIsAutorizanteAction = { type: 'setIsAutorizante'; value: boolean };

export type RelatedPartyFormAction =
  | SetInitialStateAction
  | SetRelationTypeAction
  | SetNameAction
  | SetEmailAction
  | SetPhoneAction
  | SetNifNipsAction
  | SetAddressAction
  | SetCivilStatusAction
  | SetMarriageRegimeAction
  | SetMarriedToAction
  | SetAgeAction
  | SetNacionalidadeAction
  | SetIsBornInPortugalAction
  | SetPlaceOfBirthFreguesiaAction
  | SetPlaceOfBirthConcelhoAction
  | setPlaceOfBirthAction
  | SetIdDocumentTypeAction
  | SetIdDocumentNumberAction
  | SetIdDocumentIssuanceAuthorityAction
  | SetIdDocumentIssueDateAction
  | SetIdDocumentExpiryDateAction
  | SetCorpAddressAction
  | SetCorpAddressParishAction
  | SetCorpAddressMunicipalityAction
  | SetCorpAddressDistrictAction
  | SetCorpCapitalAction
  | SetCorpAddressPostalCode
  | SetCorpRepAuthorityAction
  | SetIsFiadorAction
  | setIsAutorizanteAction;

export type RelatedPartyFormState = Omit<Partial<RelatedPartyCreateDto>, 'jobId' | 'entityType'> & {
  isBornInPortugal?: boolean;
};

export const initCreateRelatedPartyData: RelatedPartyFormState = {
  relationType: RelatedPartyRelationType.PERSON,
  nacionalidade: 'PRT',
  isFiador: false,
  isAutorizante: false,

  name: undefined,
  email: undefined,
  nif: undefined,
  address: undefined,
  phone: undefined,

  civilStatus: undefined,
  marriageRegime: undefined,
  marriedTo: undefined,

  age: undefined,
  isBornInPortugal: true,
  placeOfBirthFreguesia: undefined,
  placeOfBirthConcelho: undefined,
  placeOfBirth: undefined,
  idDocumentType: undefined,
  idDocumentNumber: undefined,
  idDocumentIssuanceAuthority: undefined,
  idDocumentIssueDate: undefined,
  idDocumentExpiryDate: undefined,
  corpRepAuthority: undefined,

  corpAddress: undefined,
  corpAddressParish: undefined,
  corpAddressMunicipality: undefined,
  corpAddressDistrict: undefined,
  corpAddressPostalCode: undefined,
  corpCapital: undefined,
};

export function useRelatedPartyForm() {
  const [form, dispatch] = useReducer((state: RelatedPartyFormState, action: RelatedPartyFormAction) => {
    switch (action.type) {
      case 'setInitialState':
        return { ...state, ...action.value };

      case 'setName': {
        return { ...state, name: action.value };
      }
      case 'setEmail': {
        return { ...state, email: action.value ? action.value.trim() : '' };
      }
      case 'setPhone': {
        return { ...state, phone: action.value };
      }
      case 'setNifNips': {
        return { ...state, nif: action.value };
      }
      case 'setAddress': {
        return { ...state, address: action.value };
      }
      case 'setRelationType': {
        const rest = {} as RelatedPartyFormState;
        if (action.value !== RelatedPartyRelationType.COMPANY) {
          rest.corpAddress = undefined;
          rest.corpAddressParish = undefined;
          rest.corpAddressPostalCode = undefined;
          rest.corpAddressMunicipality = undefined;
          rest.corpAddressDistrict = undefined;
          rest.corpCapital = undefined;
        }
        if (action.value !== RelatedPartyRelationType.PERSON) {
          rest.phone = undefined;
          rest.email = undefined;
          rest.nacionalidade = undefined;
          rest.corpRepAuthority = undefined;
          rest.idDocumentExpiryDate = undefined;
          rest.idDocumentIssueDate = undefined;
          rest.idDocumentIssuanceAuthority = undefined;
          rest.idDocumentNumber = undefined;
          rest.idDocumentType = undefined;
        }
        if (action.value === RelatedPartyRelationType.PERSON) {
          rest.nacionalidade = 'PRT';
        }
        return { ...state, relationType: action.value, ...rest };
      }
      case 'setCivilStatus': {
        const rest = {} as RelatedPartyFormState;
        if (action.value === CivilStatus.SINGLE) {
          rest.marriageRegime = undefined;
          rest.marriedTo = undefined;
        }
        return { ...state, civilStatus: action.value, ...rest };
      }
      case 'setMarriageRegime': {
        return { ...state, marriageRegime: action.value };
      }
      case 'setMarriedTo': {
        return { ...state, marriedTo: action.value };
      }
      case 'setAge': {
        return { ...state, age: action.value };
      }
      case 'setNacionalidade': {
        return { ...state, nacionalidade: action.value };
      }
      case 'setIsBornInPortugal': {
        return { ...state, isBornInPortugal: action.value };
      }
      case 'setPlaceOfBirthFreguesia': {
        return { ...state, placeOfBirthFreguesia: action.value };
      }
      case 'setPlaceOfBirthConcelho': {
        return { ...state, placeOfBirthConcelho: action.value };
      }
      case 'setPlaceOfBirth': {
        return { ...state, placeOfBirth: action.value };
      }
      case 'setIdDocumentType': {
        return { ...state, idDocumentType: action.value };
      }
      case 'setIdDocumentNumber': {
        return { ...state, idDocumentNumber: action.value };
      }
      case 'setIdDocumentIssuanceAuthority': {
        return { ...state, idDocumentIssuanceAuthority: action.value };
      }
      case 'setIdDocumentIssueDate': {
        return { ...state, idDocumentIssueDate: action.value };
      }
      case 'setIdDocumentExpiryDate': {
        return { ...state, idDocumentExpiryDate: action.value };
      }
      case 'setCorpAddress': {
        return { ...state, corpAddress: action.value };
      }
      case 'setCorpAddressParish': {
        return { ...state, corpAddressParish: action.value };
      }
      case 'setCorpAddressMunicipality': {
        return { ...state, corpAddressMunicipality: action.value };
      }
      case 'setCorpAddressDistrict': {
        return { ...state, corpAddressDistrict: action.value };
      }
      case 'setCorpCapital': {
        return { ...state, corpCapital: action.value };
      }
      case 'setCorpAddressPostalCode': {
        return { ...state, corpAddressPostalCode: action.value };
      }
      case 'setCorpRepAuthority': {
        return { ...state, corpRepAuthority: action.value };
      }
      case 'setIsFiador': {
        return { ...state, isFiador: action.value };
      }

      case 'setIsAutorizante': {
        return { ...state, isAutorizante: action.value };
      }

      default:
        return state;
    }
  }, {});

  const validate = useCallback<() => string[]>(() => {
    const errorMsgs = [];
    if (!form.name) {
      errorMsgs.push('nameIsRequired');
    }
    if (form.email && !isEmailValid(form.email)) {
      errorMsgs.push('invalidEmail');
    }
    if (!form.corpAddressPostalCode && form.relationType === RelatedPartyRelationType.COMPANY) {
      errorMsgs.push('Postal code is required');
    }
    if (form.corpAddressPostalCode) {
      if (!isPostalCodeValid(form.corpAddressPostalCode)) {
        errorMsgs.push('Invalid postal code');
      }
    }
    if (form.nif && !isNifValid(form.nif)) {
      errorMsgs.push('nifIsNotValid');
    }

    if (!form.relationType || !isEnum(form.relationType, RelatedPartyRelationType)) {
      errorMsgs.push('relationTypeIsRequired');
    }

    if (form.phone && !isPhoneValid(form.phone)) {
      errorMsgs.push('invalidPhone');
    }

    return errorMsgs;
  }, [form]);

  return { form, dispatch, validate };
}
