interface AddressParts {
  addressLine: string | null;
  postalCode: string | null;
  location: string | null;
}

export const getAddressParts = (address: string): AddressParts => {
  const addressParts = address.match(/^(.*?),?\s*(\d{4}-\d{3})\s*([A-ZÇÁÉÍÓÚÂÊÎÔÛÃÕ\s-]+)$/i);

  if (addressParts) {
    return {
      addressLine: addressParts[1] ? addressParts[1].trim() : null,
      postalCode: addressParts[2] ? addressParts[2].trim() : null,
      location: addressParts[3] ? addressParts[3].trim() : null,
    };
  }

  return {
    addressLine: null,
    postalCode: null,
    location: null,
  };
};
