import { useTranslation } from 'react-i18next';
import { Grid } from '@chakra-ui/react';

import 'react-phone-input-2/lib/style.css';
import { ContractFieldsFormAction, ContractFieldsFormState } from './useContractFieldsForm';
import { FormInputField } from '../../../../../libs/ui/atoms/src/lib/input-field/form-input-field';
import { DatePickerField } from '../../../../../libs/ui/atoms/src/lib/input-field/DatePickerField';

export interface ContractFieldsFormProps {
  form: ContractFieldsFormState;
  dispatch: (action: ContractFieldsFormAction) => void;

  isShowCpcvFields?: boolean;
  isShowRentalFields?: boolean;
}

export const ContractFieldsForm = ({
  form,
  dispatch,
  isShowCpcvFields,
  isShowRentalFields,
}: ContractFieldsFormProps) => {
  const { t } = useTranslation();

  return (
    <Grid columnGap={'24px'} rowGap={'16px'} gridTemplateColumns={'1fr'} mr={'20px'}>
      {(isShowCpcvFields || isShowRentalFields) && (
        <>
          <FormInputField.IBAN
            label={t('contractFields.form.ibanPayer')}
            value={form.ibanPayer ?? undefined}
            onBlurOrValid={value => dispatch({ type: 'setIbanPayer', value })}
            showNotIbanCheckbox={true}
            isNotIbanCheckboxChecked={form.metadata?.isIbanPayerNotIban}
            onNotIbanCheckboxChange={(value: boolean) => dispatch({ type: 'setIbanPayerNotIban', value })}
          />
          <FormInputField.IBAN
            label={t('contractFields.form.ibanBeneficiary')}
            value={form.ibanBeneficiary ?? undefined}
            onBlurOrValid={value => dispatch({ type: 'setIbanBeneficiary', value })}
            showNotIbanCheckbox={true}
            isNotIbanCheckboxChecked={form.metadata?.isIbanBeneficiaryNotIban}
            onNotIbanCheckboxChange={(value: boolean) => dispatch({ type: 'setIbanBeneficiaryNotIban', value })}
          />
          <FormInputField
            label={t('contractFields.form.amiLicenseNumber')}
            value={form.amiLicenseNumber}
            onChange={value => dispatch({ type: 'setAmiLicenseNumber', value })}
          />
          <FormInputField
            label={t('contractFields.form.amiCompanyName')}
            value={form.amiCompanyName}
            onChange={value => dispatch({ type: 'setAmiCompanyName', value })}
          />
          <FormInputField.NIF
            label={t('contractFields.form.amiCompanyNif')}
            value={form.amiCompanyNif}
            onBlurOrValid={value => dispatch({ type: 'setAmiCompanyNIf', value })}
          />
          <FormInputField.Price
            label={t('contractFields.form.rentalDepositPriceEur')}
            value={Number(form.rentalDepositPriceEur) ?? Number(form.cpcvDepositPriceEur)}
            onChange={value => {
              dispatch({ type: 'setRentalDepositPriceEur', value: Number(value).toFixed(2) });
              dispatch({ type: 'setCpcvDepositPriceEur', value: Number(value).toFixed(2) });
            }}
          />
        </>
      )}

      {isShowCpcvFields && (
        <>
          <FormInputField.Price
            label={t('contractFields.form.cpcvSalePriceEur')}
            value={Number(form.cpcvSalePriceEur)}
            onChange={value => dispatch({ type: 'setCpcvSalePriceEur', value: Number(value).toFixed(2) })}
          />
          <FormInputField
            type="number"
            label={t('contractFields.form.cpcvDeadlineDays')}
            value={form.cpcvDeadlineDays}
            onChange={value => dispatch({ type: 'setCpcvDeadlineDays', value: Number(value) })}
          />
        </>
      )}

      {isShowRentalFields && (
        <>
          <FormInputField
            type="number"
            label={t('contractFields.form.rentalTermMonths')}
            value={form.rentalTermMonths}
            onChange={value => dispatch({ type: 'setRentalTermMonths', value: Number(value) })}
          />
          <DatePickerField
            isInWidget
            label={t('contractFields.form.rentalTermStartDay')}
            value={form.rentalTermStartDay}
            onChange={value => dispatch({ type: 'setRentalTermStartDay', value })}
            portalId={'chakra-contract-fields-rental-term-start-day'}
          />
          <DatePickerField
            isInWidget
            label={t('contractFields.form.rentalTermEndDay')}
            value={form.rentalTermEndDay}
            onChange={value => dispatch({ type: 'setRentalTermEndDay', value })}
            portalId={'chakra-contract-fields-rental-term-end-day'}
          />
          <FormInputField.Price
            label={t('contractFields.form.rentalMonthlyRentPriceEur')}
            value={Number(form.rentalMonthlyRentPriceEur)}
            onChange={value => dispatch({ type: 'setRentalMonthlyRentPriceEur', value: Number(value).toFixed(2) })}
          />
        </>
      )}
    </Grid>
  );
};
