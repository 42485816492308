export function getRawIndexFromCaret(formattedValue: string, caretPos: number) {
  let rawIndex = 0;
  for (let i = 0; i < caretPos && i < formattedValue.length; i++) {
    if (formattedValue[i] !== ' ') {
      rawIndex++;
    }
  }
  return rawIndex;
}

export function moveCaret(inputEl: HTMLInputElement | null, caretPos: number) {
  requestAnimationFrame(() => {
    if (inputEl) {
      inputEl.setSelectionRange(caretPos, caretPos);
    }
  });
}
