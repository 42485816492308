import { EditIcon } from '@chakra-ui/icons';
import { Icon, useColorModeValue, useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { EntityType, Job, SubmissionSigningStatus } from '../../api';
import { signingDefaultLanguage, useCreateSigningMutation } from '../../api/signing';
import { useTypedSelector } from '../../store';
import { AppButton, AppButtonColorScheme, AppButtonType } from '../../libs/ui/atoms/src/lib/appButton';

export interface JobSignButtonProps {
  isAccordionItem?: boolean;
}

export const JobSignButton = (props: JobSignButtonProps) => {
  const { isAccordionItem } = props;

  const { t } = useTranslation();
  const toast = useToast();

  const textColor = useColorModeValue('blue.800', 'blue.800');
  const menuItemBg = useColorModeValue('yellow.50', '_gray.400');
  const menuItemHoverBg = useColorModeValue('yellow.100', '_gray.300');
  const borderColor = useColorModeValue('_gray.300', '_gray.300');

  const isResultPage = useMatch('/job-result/:jobId');

  const navigate = useNavigate();

  const job = useTypedSelector(state => state.job.selectedJob) as Job;
  const signing = job?.signing;

  const [createSigning, { data, isLoading }] = useCreateSigningMutation();

  const handleClick = useCallback(async () => {
    if (
      !signing ||
      (signing?.submission?.status &&
        [SubmissionSigningStatus.SUCCESS, SubmissionSigningStatus.REJECT, SubmissionSigningStatus.ERROR].includes(
          signing.submission.status,
        ))
    ) {
      try {
        const result = await createSigning({
          entityId: job.id,
          entityType: EntityType.JOB,
          language: signingDefaultLanguage,
        }).unwrap();
        navigate(`/signings/${result?.id}`);
      } catch (error) {
        toast({
          title: t('error'),
          description: t('job.pages.details.signError'),
          status: 'error',
          duration: 1500,
        });
      }
    } else {
      navigate(`/signings/${signing.id}`);
    }
  }, [createSigning, navigate, signing, job, data, toast, t]);

  return (
    <>
      {job &&
        job.id &&
        (isAccordionItem ? (
          <AppButton
            key={`signin`}
            buttonType={AppButtonType.PRIMARY}
            colorSchemes={[AppButtonColorScheme.TRANSPARENT]}
            bg={menuItemBg}
            leftIcon={<Icon as={EditIcon} boxSize={5} width="20px" height="20px" color={textColor} />}
            borderColor={borderColor}
            onClick={handleClick}
            color={textColor}
            _hover={{ bg: menuItemHoverBg }}
          >
            {t('pages.signing.toolbar.button')}
          </AppButton>
        ) : (
          isResultPage && (
            <AppButton
              buttonType={AppButtonType.PRIMARY}
              isLoading={isLoading}
              leftIcon={<EditIcon color={'white'} w="24px" h="24px" />}
              onClick={handleClick}
              title={t('pages.details.sign')}
            />
          )
        ))}
    </>
  );
};
