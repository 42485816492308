import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useCheckboxGroup,
  useColorModeValue,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import { useTypedSelector } from '../../../../../store';
import { useUpdateJobMutation } from '../../../../../api';
import { selectJob } from '../../../../../store/job';
import { ContractType, useGetTemplatesQuery } from 'api/template';
import { MaybeWrapAccordion } from './MaybeWrapAccordion';
import { AppButton, AppButtonType } from '../../../../../libs/ui/atoms/src/lib/appButton';
import { VCSearch } from '../../../../../libs/ui/atoms/src/lib/search/Search';
import { useAppToast } from 'libs/ui/hooks';
import { TableHeader } from 'libs/ui/table';
import { VCTabs } from '../../../../../libs/ui/atoms/src/lib/tab/Tabs';
import { useAppBreakpoint } from '../../../../../theme/hooks';

interface JobTemplate {
  id: string;
  isSelected: boolean;
  name: string;
  description: string;
  contractType?: ContractType;
}

const columnHelper = createColumnHelper<JobTemplate>();

const ColumnsWidth = ['2%', '48%', '50%'];
const ColumnsMinWidth = ['20px', '150px', '150px'];

interface JobTemplatesWidgetProps {
  jobId: string;
  title?: string;

  isExpanded: boolean;
  onAccordionToggle: () => void;
}

export function JobTemplatesWidget({ jobId, title, isExpanded, onAccordionToggle }: JobTemplatesWidgetProps) {
  const { t } = useTranslation();
  const { showApiError } = useAppToast();

  const textColor = useColorModeValue('brand.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const cardBackgroundColor = useColorModeValue('white', 'navy.800');

  const [updateJob] = useUpdateJobMutation();
  const { data } = useGetTemplatesQuery({
    refetchOnReconnect: true,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [sorting, setSorting] = React.useState<SortingState>([{ id: 'name', desc: false }]);
  const job = useTypedSelector(state => (jobId ? selectJob(state, jobId) : null));

  const templates = useMemo<JobTemplate[]>(() => {
    return (
      data?.map(i => ({
        id: i.id,
        isSelected: !!job?.templates?.find(t => t.id === i.id),
        name: i.name,
        description: i.description,
        contractType: i.contractType,
      })) ?? []
    );
  }, [job, data]);

  const [search, setSearch] = useState('');
  const [contractType, setContractType] = useState<ContractType | null>(ContractType.CPCV);
  const [templatesShown, setTemplatesShown] = useState([...templates]);

  useEffect(() => {
    setTemplatesShown([
      ...templates.filter(i => i.name.toLowerCase().includes(search.toLowerCase()) && i.contractType === contractType),
    ]);
  }, [search, templates, contractType]);

  const { value: selectedTemplateIds, setValue: setSelectedTemplateIds } = useCheckboxGroup({});

  const [optimisticTemplates, setOptimisticTemplates] = useState<JobTemplate[]>([]);

  useEffect(() => {
    setOptimisticTemplates(templates);
  }, [templates]);

  useEffect(() => {
    if (optimisticTemplates) {
      setSelectedTemplateIds(optimisticTemplates.filter(template => template.isSelected).map(template => template.id));
    }
  }, [optimisticTemplates]);

  const handleCheckboxClick = async (templateId: string) => {
    if (!job?.id) return;

    const newSelectedIds = selectedTemplateIds.includes(templateId)
      ? selectedTemplateIds.filter(id => id !== templateId)
      : [...selectedTemplateIds, templateId];
    setSelectedTemplateIds(newSelectedIds);

    const updatedTemplates = optimisticTemplates.map(template =>
      template.id === templateId ? { ...template, isSelected: !template.isSelected } : template,
    );
    setOptimisticTemplates(updatedTemplates);

    try {
      await updateJob({
        id: job.id,
        templateIds: updatedTemplates.filter(template => template.isSelected).map(template => template.id),
      }).unwrap();
    } catch (error) {
      showApiError(error);
      setOptimisticTemplates(templates);
    }
  };

  const columns = [
    columnHelper.accessor('isSelected', {
      id: 'isSelected',
      header: info => (
        <Flex align="center">
          <AppButton
            buttonType={AppButtonType.ICON}
            onClick={() => (selectedTemplateIds.length ? handleDeselectAll() : handleSelectAll())}
            children={
              <Icon
                as={selectedTemplateIds.length ? MdOutlineCheckBox : MdOutlineCheckBoxOutlineBlank}
                color={selectedTemplateIds.length ? 'green.300' : 'secondaryGray.600'}
                w="24px"
                h="24px"
              />
            }
          />
        </Flex>
      ),
      cell: info => (
        <Flex align="center">
          <AppButton
            buttonType={AppButtonType.ICON}
            onClick={() => {
              return handleCheckboxClick(info.row.original.id);
            }}
            children={
              <Icon
                as={info.getValue() ? MdOutlineCheckBox : MdOutlineCheckBoxOutlineBlank}
                color={info.getValue() ? 'green.300' : 'secondaryGray.600'}
                w="24px"
                h="24px"
              />
            }
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Flex justifyContent="center" alignItems="center" gap="4px">
          <TableHeader>{t('pages.details.name')}</TableHeader>
          <ChevronDownIcon w="12px" h="24px" />
        </Flex>
      ),
      cell: info => (
        <Text color={textColor} p="0" fontSize="14px">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: () => (
        <Flex justifyContent="center" alignItems="center" gap="4px">
          <TableHeader>{t('pages.details.description')}</TableHeader>
          <ChevronDownIcon w="12px" h="24px" />
        </Flex>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
  ];

  const table = useReactTable({
    data: search ? templatesShown : templatesShown,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleDeselectAll = async () => {
    const deselectAllTemplates = optimisticTemplates.map(template => ({
      ...template,
      isSelected: false,
    }));

    setOptimisticTemplates(deselectAllTemplates);

    if (job?.id) {
      try {
        await updateJob({
          id: job.id,
          templateIds: [],
        }).unwrap();
      } catch (error) {
        showApiError(error);
        setOptimisticTemplates(templates);
      }
    }
  };

  const handleSelectAll = async () => {
    const selectedAllTemplates = optimisticTemplates.map(template => ({
      ...template,
      isSelected: true,
    }));

    setOptimisticTemplates(selectedAllTemplates);

    if (job?.id) {
      try {
        await updateJob({
          id: job.id,
          templateIds: selectedAllTemplates.map(template => template.id),
        }).unwrap();
      } catch (error) {
        showApiError(error);
        setOptimisticTemplates(templates);
      }
    }
  };

  const getContractSelectorAndSearchElement = () => (
    <>
      <Box pt={!isAboveLg ? '2px' : 0}>
        <VCTabs<ContractType>
          isHideContent
          selectedTab={contractType}
          onChange={v => setContractType(v)}
          tabs={[
            {
              titleKey: 'cpcv',
              value: ContractType.CPCV,
              remark: templates.filter(t => t.isSelected && t.contractType === ContractType.CPCV).length.toString(),
            },
            {
              titleKey: 'rental',
              value: ContractType.RENTAL,
              remark: templates.filter(t => t.isSelected && t.contractType === ContractType.RENTAL).length.toString(),
            },
            {
              titleKey: 'other',
              remark: templates.filter(t => t.isSelected && !t.contractType).length.toString(),
            },
          ]}
        />
      </Box>
      <VCSearch isFullWidth={!isAboveLg} search={search} onSearch={setSearch} />
    </>
  );

  const isAboveLg = useAppBreakpoint('lg');

  return (
    <Card flexDirection="column" w="100%" p={'0'} position="static" flex={1}>
      <Flex direction="column" gap="16px">
        <MaybeWrapAccordion
          isAccordion
          isExpanded={isExpanded}
          onToggle={onAccordionToggle}
          buttonChildren={
            <Flex
              justifyContent={isAboveLg ? 'flex-start' : 'center'}
              alignItems={isAboveLg ? 'center' : 'flex-start'}
              p={'24px'}
              width={'100%'}
              gap="24px"
              flexDir={{ base: 'column', lg: 'row' }}
            >
              <Text fontSize={'20px'} color={textColor} fontWeight="700">
                {title}
              </Text>

              {isAboveLg && (
                <Flex gap="16px" flex={1} flexWrap="wrap">
                  {getContractSelectorAndSearchElement()}
                </Flex>
              )}
            </Flex>
          }
        >
          <Box position="relative" w="100%" p={'0 16px 16px 16px'}>
            {!isAboveLg && (
              <Flex flexDir={'column'} gap="16px" pb={'8px'}>
                {getContractSelectorAndSearchElement()}
              </Flex>
            )}

            <Box overflowY="auto" maxH={'calc(350px - 48px)'}>
              <Table variant="simple" color="gray.500">
                <Thead position="sticky" top="0" zIndex="docked" bg={cardBackgroundColor}>
                  {table.getHeaderGroups().map(headerGroup => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, idx) => (
                        <Th
                          key={header.id}
                          colSpan={header.colSpan}
                          p="0 0 8px 0"
                          w={ColumnsWidth[idx]}
                          minW={ColumnsMinWidth[idx]}
                          borderColor="transparent"
                          cursor="pointer"
                          textTransform="capitalize"
                          onClick={header.column.getToggleSortingHandler()}
                          _before={{
                            content: '""',
                            position: 'absolute',
                            top: '36px',
                            left: 0,
                            width: '100%',
                            height: '1px',
                            bg: borderColor,
                          }}
                        >
                          <Flex
                            justifyContent="space-between"
                            align="center"
                            fontSize={{ sm: '10px', lg: '12px' }}
                            color="gray.400"
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </Flex>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>

                <Tbody>
                  {table.getRowModel().rows.map(row => (
                    <Tr key={row.id} h="48px">
                      {row.getVisibleCells().map((cell, idx) => (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          borderColor="transparent"
                          p="0"
                          paddingRight="16px"
                          w={ColumnsWidth[idx]}
                          minW={ColumnsMinWidth[idx]}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
        </MaybeWrapAccordion>
      </Flex>
    </Card>
  );
}
