import { Box, Flex, Grid, Text, useColorModeValue } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmissionSigningStatus } from '../../../api';
import {
  useAbandonPreparationSigningMutation,
  useLazyGetSigningQuery,
  useSendSigningMutation,
} from '../../../api/signing';
import Card from '../../../components/card/Card';
import { DocumentsViewer } from '../../../components/documentsViewer/DocumentsViewer';
import { environment } from '../../../environments/environment';
import { useAppToast } from '../../../libs/ui/hooks';
import { useTypedSelector } from '../../../store';
import { SignersWidget } from './components/signers-widget';
import { useSigners } from './signer/use-signers';
import { AppButton, AppButtonColorScheme, AppButtonType } from '../../../libs/ui/atoms/src/lib/appButton';

export const SigningConfirmPage = () => {
  const { t } = useTranslation();
  const { showApiError } = useAppToast();
  const navigate = useNavigate();

  const { signingId } = useParams<{ signingId: string }>();
  const selectedSigning = useTypedSelector(state => state.signing.selectedSigning ?? undefined);
  const signing = signingId === selectedSigning?.id ? selectedSigning : undefined;

  const isReadyToSend = signing?.submission?.status === SubmissionSigningStatus.READY_TO_SEND;
  const isPreparedFileTooLarge = signing?.submission?.status === SubmissionSigningStatus.PREPARED_FILE_TOO_LARGE;

  const [fetchSigning, { isLoading }] = useLazyGetSigningQuery({
    pollingInterval: environment.filePollingIntervalSeconds * 1000,
  });

  const [sendSigning, { isLoading: isLoadingSend }] = useSendSigningMutation();
  const [abandonPreparationSigning, { isLoading: isLoadingAbandon }] = useAbandonPreparationSigningMutation();

  const { isLoading: isSignersLoading, signers } = useSigners({
    signingId: signing?.id || '',
  });

  const load = useCallback(() => {
    fetchSigning({ signingId: signingId ?? '' });
  }, [fetchSigning, signingId]);

  useEffect(() => {
    load();
  }, [load]);

  const onSend = useCallback(async () => {
    if (!signingId) {
      throw new Error('Invalid application state! Signing ID is not defined');
    }

    try {
      await sendSigning({ id: signingId }).unwrap();
      navigate(`/signings/${signingId}`, { replace: true });
    } catch (error) {
      showApiError(error);
    }
  }, [navigate, sendSigning, showApiError, signingId]);

  const onAbandonPreparation = useCallback(async () => {
    if (!signingId) {
      throw new Error('Invalid application state! Signing ID is not defined');
    }

    try {
      await abandonPreparationSigning({ id: signingId }).unwrap();
      navigate(`/signings/${signingId}`, { replace: true });
    } catch (error) {
      showApiError(error);
    }
  }, [abandonPreparationSigning, navigate, showApiError, signingId]);

  useEffect(() => {
    if (!isReadyToSend && !isPreparedFileTooLarge) {
      navigate(`/signings/${signingId}`, { replace: true });
    }
  }, [navigate, isReadyToSend, isPreparedFileTooLarge, signingId]);

  const currentUrl = signing?.documents?.[0]?.url;

  const cardBackgroundColor = useColorModeValue('_gray.200', 'navy.900');

  if (isLoading || isSignersLoading || !signing || !signing.id) {
    return <Box>...{t('loading')}</Box>;
  }

  const isButtonsDisabled = isLoadingSend || isLoadingAbandon;

  return (
    <Flex direction="column" gap="24px">
      <Card
        w="100%"
        p={0}
        bg={cardBackgroundColor}
        overflowX={{ sm: 'auto', lg: 'hidden' }}
        position="static"
        display={'flex'}
        flexDirection="column"
        gap={'24px'}
      >
        <Grid gap="12px" templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
          <Card flexDirection={'column'} p="0">
            <Text fontSize="16px" fontWeight="bold" p="24px 24px 0 24px">
              {t('pages.signing.hereIsTheDocumentPreparedToSign')}
            </Text>
            <DocumentsViewer previewUrl={currentUrl} maxH="calc(100vh - 175px)" h="max(calc(100vh - 175px), 500px)" />
          </Card>
          <Card flexDirection={'column'} gap="16px" p="0">
            <Card flexDirection={'column'} p="0" flex="1">
              <Text fontSize="16px" fontWeight="bold" p="24px 24px 0 24px">
                {t('pages.signing.hereAreTheSignatoriesPleaseReviewAllTHeInformationBeforeProceeding')}
              </Text>
              <SignersWidget
                isInConfirmView
                signing={signing}
                signers={signers}
                isLoading={isSignersLoading}
                isSerial={signing.isSerial}
                language={signing.language}
                flex="1"
              />
            </Card>

            <Flex
              gap="24px"
              justifyContent="end"
              direction={{ base: 'column', md: 'row' }}
              position="relative"
              p="24px"
            >
              <AppButton
                buttonType={AppButtonType.MAIN}
                isDisabled={isButtonsDisabled}
                isLoading={isLoadingAbandon}
                onClick={onAbandonPreparation}
                title={t('pages.signing.backToEdit')}
                colorSchemes={[AppButtonColorScheme.DISABLED]}
              />
              <AppButton
                buttonType={AppButtonType.MAIN}
                isDisabled={isButtonsDisabled || !isReadyToSend}
                onClick={onSend}
                isLoading={isLoadingSend}
                title={t('pages.signing.proceedToSigning')}
                tooltip={
                  !isReadyToSend
                    ? t('pages.signing.preparedFileSizeLimitExceeded', { size: environment.fileSizeLimit / 1048576 })
                    : ''
                }
              />
            </Flex>
          </Card>
        </Grid>
      </Card>
    </Flex>
  );
};
