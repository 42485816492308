import { useCallback, useReducer, useState } from 'react';
import { isIbanValid, isNifValid } from 'utils/validators';
import {
  ContractFields,
  CPCVAndRentalCommonFields,
  CPCVContractFields,
  RentalContractFields,
} from '../../../../../api/extra-data/contract-fields';

type SetInitialStateAction = { type: 'setInitialState'; value: ContractFieldsFormState };

type SetIbanPayerAction = { type: 'setIbanPayer'; value: string };
type SetIbanBeneficiaryAction = { type: 'setIbanBeneficiary'; value: string };

type SetIbanPayerNotIbanAction = { type: 'setIbanPayerNotIban'; value: boolean };
type SetIbanBeneficiaryNotIbanAction = { type: 'setIbanBeneficiaryNotIban'; value: boolean };

type SetAmiLicenseNumberAction = { type: 'setAmiLicenseNumber'; value: string };
type SetAmiCompanyNifAction = { type: 'setAmiCompanyNIf'; value: string | null };
type SetAmiCompanyNameAction = { type: 'setAmiCompanyName'; value: string };

type SetCpcvSalePriceEurAction = { type: 'setCpcvSalePriceEur'; value: string };
type SetCpcvDepositPriceEurAction = { type: 'setCpcvDepositPriceEur'; value: string };
type SetCpcvDeadlineDaysAction = { type: 'setCpcvDeadlineDays'; value: number };

type SetRentalTermMonthsAction = { type: 'setRentalTermMonths'; value: number };
type SetRentalTermStartDayAction = { type: 'setRentalTermStartDay'; value: Date | null };
type SetRentalTermEndDayAction = { type: 'setRentalTermEndDay'; value: Date | null };
type SetRentalDepositPriceEurAction = { type: 'setRentalDepositPriceEur'; value: string };
type SetRentalMonthlyRentPriceEurAction = { type: 'setRentalMonthlyRentPriceEur'; value: string };
type SetAllUpdatesAction = { type: 'setAllUpdates'; value: ContractFieldsFormState };

export type ContractFieldsFormAction =
  | SetInitialStateAction
  | SetIbanPayerAction
  | SetIbanBeneficiaryAction
  | SetIbanPayerNotIbanAction
  | SetIbanBeneficiaryNotIbanAction
  | SetAmiLicenseNumberAction
  | SetAmiCompanyNifAction
  | SetAmiCompanyNameAction
  | SetCpcvSalePriceEurAction
  | SetCpcvDepositPriceEurAction
  | SetCpcvDeadlineDaysAction
  | SetRentalTermMonthsAction
  | SetRentalTermStartDayAction
  | SetRentalTermEndDayAction
  | SetRentalDepositPriceEurAction
  | SetRentalMonthlyRentPriceEurAction
  | SetAllUpdatesAction;

export type ContractFieldsFormState = ContractFields;

export const initContractFieldsData: ContractFieldsFormState = {};

export const commonContractFields: (keyof CPCVAndRentalCommonFields)[] = [
  'ibanPayer',
  'ibanBeneficiary',
  'amiLicenseNumber',
  'amiCompanyName',
  'amiCompanyNif',
];
export const cpcvContractFiels: (keyof CPCVContractFields)[] = [
  ...commonContractFields,
  'cpcvDeadlineDays',
  'cpcvDepositPriceEur',
  'cpcvSalePriceEur',
];
export const rentalContractFiels: (keyof RentalContractFields)[] = [
  ...commonContractFields,
  'rentalDepositPriceEur',
  'rentalMonthlyRentPriceEur',
  'rentalTermStartDay',
  'rentalTermEndDay',
  'rentalTermMonths',
];

export function useContractFieldsForm() {
  const [isValid, setIsValid] = useState(false);

  const [form, dispatch] = useReducer((state: ContractFieldsFormState, action: ContractFieldsFormAction) => {
    switch (action.type) {
      case 'setInitialState':
        return { ...state, ...action.value };

      case 'setIbanPayer': {
        return { ...state, ibanPayer: action.value ? action.value : null };
      }
      case 'setIbanBeneficiary': {
        return { ...state, ibanBeneficiary: action.value ? action.value : null };
      }
      case 'setIbanPayerNotIban': {
        return { ...state, metadata: { ...state.metadata, isIbanPayerNotIban: action.value } };
      }
      case 'setIbanBeneficiaryNotIban': {
        return { ...state, metadata: { ...state.metadata, isIbanBeneficiaryNotIban: action.value } };
      }
      case 'setAmiLicenseNumber': {
        return { ...state, amiLicenseNumber: action.value };
      }
      case 'setAmiCompanyNIf': {
        return { ...state, amiCompanyNif: action.value ? action.value : null };
      }
      case 'setAmiCompanyName': {
        return { ...state, amiCompanyName: action.value };
      }

      case 'setCpcvSalePriceEur': {
        return { ...state, cpcvSalePriceEur: action.value };
      }
      case 'setCpcvDepositPriceEur': {
        return { ...state, cpcvDepositPriceEur: action.value };
      }
      case 'setCpcvDeadlineDays': {
        return { ...state, cpcvDeadlineDays: action.value };
      }

      case 'setRentalTermMonths': {
        return { ...state, rentalTermMonths: action.value };
      }
      case 'setRentalTermStartDay': {
        return { ...state, rentalTermStartDay: action.value };
      }
      case 'setRentalTermEndDay': {
        return { ...state, rentalTermEndDay: action.value };
      }
      case 'setRentalDepositPriceEur': {
        return { ...state, rentalDepositPriceEur: action.value };
      }
      case 'setRentalMonthlyRentPriceEur': {
        return { ...state, rentalMonthlyRentPriceEur: action.value };
      }
      case 'setAllUpdates': {
        return { ...state, ...action.value };
      }

      default:
        return state;
    }
  }, initContractFieldsData);

  const validate = useCallback(() => {
    const errorMsgs = [];

    if (form.ibanPayer && !form.metadata?.isIbanPayerNotIban && !isIbanValid(form.ibanPayer)) {
      errorMsgs.push('contractFields.errors.ibanPayerIsNotValid');
    }
    if (form.ibanBeneficiary && !form.metadata?.isIbanBeneficiaryNotIban && !isIbanValid(form.ibanBeneficiary)) {
      errorMsgs.push('contractFields.errors.ibanBeneficiaryIsNotValid');
    }
    if (form.amiCompanyNif && !isNifValid(form.amiCompanyNif)) {
      errorMsgs.push('contractFields.errors.companyNifInvalid');
    }

    return errorMsgs;
  }, [form]);

  return { form, dispatch, validate, isValid, setIsValid };
}
