import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Tab,
  Table,
  TabList,
  TabProps,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import Card from 'components/card/Card';
import { ApproveTick, RecycleBing } from 'libs/ui/atoms/src/lib/icons/icons';
import { Job, JobStatus } from '../../../../api/job.private';
import { OnChangeFn } from '@tanstack/table-core/src/types';
import { useVirtualizer } from '@tanstack/react-virtual';
import { iconByJobStatus, iconColorByJobStatus, jobStatusTranslateByJobStatus } from '../variables/job-status';
import { formatDate } from '../../../../utils/date';
import { useTranslation } from 'react-i18next';
import { MdOutlineArchive, MdOutlineUnarchive } from 'react-icons/md';
import { useColumnWidth } from '../../../../hooks/use-column-width';
import { DocumentOutFilledIcon } from '../../../../components/icons/Icons';
import { getIconForSorting, TableHeader } from '../../../../libs/ui/table';

import './table.css';
import { AppButton, AppButtonType, AppButtonColorScheme } from '../../../../libs/ui/atoms/src/lib/appButton';

const columnHelper = createColumnHelper<Job>();

export enum TabKind {
  RECENT = 'RECENT',
  THIS_WEEK = 'THIS_WEEK',
  ALL = 'ALL',
  ARCHIVED = 'ARCHIVED',
}

const tabTranslateByTabKind: Record<TabKind, string> = {
  [TabKind.RECENT]: 'pages.jobs.tabs.recent',
  [TabKind.THIS_WEEK]: 'pages.jobs.tabs.thisWeek',
  [TabKind.ALL]: 'pages.jobs.tabs.all',
  [TabKind.ARCHIVED]: 'pages.jobs.tabs.archived',
};

export const tabKinds = Object.values(TabKind);

export interface JobTableProps {
  tabIndex: number;
  jobs: Job[];
  sorting: SortingState;
  onChangeTab: (index: number) => void;
  onArchiveClick: (job: Job) => void;
  onSortingChange: OnChangeFn<SortingState>;
  isFetching?: boolean;
  fetchNextPage?: () => void;
  totalCount?: number;
  onClickJob?: (job: Job) => void;

  isShowOwnerColumn: boolean;
}

export function JobTable({
  tabIndex,
  jobs,
  sorting,
  onChangeTab,
  onSortingChange,
  isFetching,
  fetchNextPage,
  totalCount,
  onClickJob,
  onArchiveClick,
  isShowOwnerColumn,
}: JobTableProps) {
  const { t } = useTranslation();

  const { colorMode } = useColorMode();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const rowHoverBg = useColorModeValue('secondaryGray.400', 'whiteAlpha.300');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const sidebarBg = useColorModeValue('white', 'navy.800');

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const columns = useMemo(
    () => [
      columnHelper.accessor('createdAt', {
        id: 'createdAt',
        enableSorting: true,
        header: ({ column }) => (
          <Flex justifyContent="center" alignItems="center" gap="4px">
            <TableHeader>{t('pages.jobs.tableHeaders.date')}</TableHeader>
            {getIconForSorting(column.getIsSorted())}
          </Flex>
        ),
        cell: info => (
          <Flex align="center" justify="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {formatDate(info.getValue())}
            </Text>
          </Flex>
        ),
      }),
      columnHelper.accessor('name', {
        id: 'name',
        enableSorting: true,
        header: ({ column }) => (
          <Flex justifyContent="center" alignItems="center" gap="4px">
            <TableHeader>{t('pages.jobs.tableHeaders.description')}</TableHeader>
            {getIconForSorting(column.getIsSorted())}
          </Flex>
        ),
        cell: info => (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700" noOfLines={1}>
              {info.getValue()}
            </Text>
          </Flex>
        ),
      }),
      ...(isShowOwnerColumn
        ? [
            columnHelper.accessor('ownerUser', {
              id: 'ownerUser',
              enableSorting: true,
              header: ({ column }) => (
                <Flex justifyContent="center" alignItems="center" gap="4px">
                  <TableHeader>{t('pages.jobs.tableHeaders.owner')}</TableHeader>
                  {getIconForSorting(column.getIsSorted())}
                </Flex>
              ),
              cell: info => (
                <Flex align="center" alignContent={'center'} gap={'8px'}>
                  <Avatar
                    mx="auto"
                    name={info.getValue()?.name}
                    h="32px"
                    w="32px"
                    className="table-avatar"
                    borderColor={borderColor}
                  />
                  <Text color={textColor} fontSize="sm" fontWeight="700" noOfLines={1}>
                    {info.getValue()?.name}
                  </Text>
                </Flex>
              ),
            }),
          ]
        : []),
      columnHelper.accessor('status', {
        id: 'status',
        enableSorting: true,
        header: ({ column }) => (
          <Flex justifyContent="center" alignItems="center" gap="4px">
            <TableHeader>{t('pages.jobs.tableHeaders.status')}</TableHeader>
            {getIconForSorting(column.getIsSorted())}
          </Flex>
        ),
        cell: info => (
          <Flex align="center">
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={iconColorByJobStatus[info.getValue()]}
              as={iconByJobStatus[info.getValue()]}
            />
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {t(jobStatusTranslateByJobStatus[info.getValue()])}
            </Text>
          </Flex>
        ),
      }),
      columnHelper.accessor('id', {
        id: 'actions',
        enableSorting: false,
        header: ({ column }) => (
          <Flex justifyContent="center" alignItems="center" gap="4px">
            <TableHeader>{t('pages.jobs.tableHeaders.action')}</TableHeader>
            {getIconForSorting(column.getIsSorted())}
          </Flex>
        ),
        cell: info => {
          const job = info.table.getRow(info.row.id).original;

          const isDisabled =
            job.status !== JobStatus['Job is completed'] && job.status !== JobStatus['job is archived'];

          return (
            <Flex align="center" gap="16px" onClick={e => e.stopPropagation()}>
              <Link to={`/job-result/${info.getValue()}`}>
                <AppButton
                  buttonType={AppButtonType.XSMALL}
                  leftIcon={<ApproveTick />}
                  isDisabled={isDisabled}
                  title={t('result')}
                />
              </Link>
              <Link to={`/jobs/${job.id}/contracts-and-parties`}>
                <AppButton
                  buttonType={AppButtonType.XSMALL}
                  leftIcon={<DocumentOutFilledIcon w="16px" h="16px" />}
                  title={t('routes.contracts&parties')}
                />
              </Link>
              <AppButton
                buttonType={AppButtonType.XSMALL}
                onClick={() => onArchiveClick(job)}
                leftIcon={
                  job.status === JobStatus.Draft ? (
                    <RecycleBing boxSize={4} />
                  ) : (
                    <Icon
                      w="16px"
                      h="16px"
                      as={job.status === JobStatus['job is archived'] ? MdOutlineUnarchive : MdOutlineArchive}
                    />
                  )
                }
                colorSchemes={[
                  job.status === JobStatus.Draft ? AppButtonColorScheme.DANGER : AppButtonColorScheme.DEFAULT,
                ]}
                title={t(
                  job.status === JobStatus['job is archived']
                    ? 'unarchive'
                    : job.status === JobStatus.Draft
                      ? 'delete'
                      : 'archive',
                )}
              />
            </Flex>
          );
        },
      }),
    ],
    [colorMode],
  );

  const dataLength = jobs.length;

  const { getWidth } = useColumnWidth(
    isShowOwnerColumn
      ? [
          { id: 'createdAt', widthPercents: 10 },
          { id: 'name', widthPercents: 20 },
          { id: 'ownerUser', widthPercents: 15 },
          { id: 'status', widthPercents: 15 },
          { id: 'actions', widthPercents: 40 },
        ]
      : [
          { id: 'createdAt', widthPercents: 15 },
          { id: 'name', widthPercents: 25 },
          { id: 'status', widthPercents: 20 },
          { id: 'actions', widthPercents: 40 },
        ],
  );

  // Fetch on scroll with debounce (to not trigger many times at once)
  const timerId = useRef<any>(null);
  const fetchMore = useCallback(
    (containerElement?: HTMLDivElement | null) => {
      clearInterval(timerId.current);
      if (containerElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerElement;
        if (scrollHeight - scrollTop - clientHeight < 500 && !isFetching && dataLength < (totalCount || 0)) {
          timerId.current = setTimeout(() => fetchNextPage?.(), 100);
        }
      }
    },
    [fetchNextPage, isFetching, dataLength, totalCount],
  );

  useEffect(() => {
    fetchMore(tableContainerRef.current);
  }, [fetchMore]);

  const table = useReactTable({
    data: jobs,
    columns,
    state: { sorting },
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    enableSorting: true,
    enableSortingRemoval: true,
    debugTable: false,
  });

  table.setOptions(old => ({
    ...old,
    onSortingChange: updaterOrValue => {
      onSortingChange(updaterOrValue);
      // if (table.getRowModel().rows.length) {
      //   rowVirtualizer.scrollToIndex?.(0);
      // }
    },
  }));

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    debug: false,
    estimateSize: () => 48,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const tabSets: TabProps = {
    fontSize: '12px',
    fontWeight: 600,
    color: 'gray.400',
    p: '9px 16px 16px',
    _selected: {
      bg: sidebarBg,
      color: textColor,
      borderRadius: '10px 10px 0 0',
    },
    _focus: { outline: 'none' },
  };

  return (
    <Box>
      <Tabs index={tabIndex} onChange={onChangeTab} variant="enclosed" w="100%" padding="0 16px">
        <TabList overflowX="auto" overflowY="hidden">
          {tabKinds.map(tab => (
            <Tab key={tab} {...tabSets} minW="fit-content">
              {t(tabTranslateByTabKind[tab])}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <Card flexDirection="column" w="100%" p="24px" overflowX={{ sm: 'hidden', lg: 'hidden' }} position="static">
        <Box overflowX="auto" overflowY="hidden" position="relative">
          <Table variant="simple">
            <Thead>
              {table.getHeaderGroups().map(headerGroup => (
                <Tr key={headerGroup.id} h="28px" display="flex" justifyContent="space-between" bg={sidebarBg}>
                  {headerGroup.headers.map(header => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        p="0 8px 4px 0px"
                        borderColor={borderColor}
                        cursor="pointer"
                        textTransform="capitalize"
                        onClick={header.column.getToggleSortingHandler()}
                        w={{ sm: '140px', md: getWidth(header.id) + '%' }}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize="12px"
                          lineHeight="24px"
                          color="gray.400"
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Box
              ref={tableContainerRef}
              onScroll={e => fetchMore(e.target as HTMLDivElement)}
              overflowY="auto"
              position="relative"
              h={{
                sm: 'calc(100vh - 264px - 28px)',
                md: 'calc(100vh - 224px - 28px)',
              }}
            >
              <Tbody h={`${rowVirtualizer.getTotalSize()}px`}>
                {rowVirtualizer.getVirtualItems().map(virtualRow => {
                  const row = rows[virtualRow.index];
                  return (
                    <Tr
                      key={row.id}
                      data-index={virtualRow.index}
                      ref={instance => rowVirtualizer.measureElement(instance)}
                      display="flex"
                      justifyContent="space-between"
                      position="absolute"
                      transform={`translateY(${virtualRow.start}px)`}
                      w="100%"
                      h="48px"
                      onClick={onClickJob ? () => onClickJob(row.original) : undefined}
                      cursor="pointer"
                      transition=".2s ease-in-out"
                      _hover={{ bg: rowHoverBg }}
                    >
                      {row.getVisibleCells().map(cell => (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          display="flex"
                          w={{ sm: '140px', md: getWidth(cell.column.id) + '%' }}
                          justifyContent="space-between"
                          borderColor="transparent"
                          p="0 8px 0 0"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>

              {isFetching && <Box>Fetching more...</Box>}
            </Box>
          </Table>
        </Box>
      </Card>
    </Box>
  );
}
