import * as React from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import { useState } from 'react';
import { Job, JobDocument } from '../../../api';
import { JobMainInfoWidget } from './components/job-main-info';
import { useTranslation } from 'react-i18next';
import { DocumentsValidationErrorTypeEnum } from './widgets/JobDocumentsWidget/DocumentValidationModals';
import { ReviewSettings } from './widgets/JobDocumentsWidget/ReviewSettings';
import { FieldState } from './widgets/JobDetailsWidget/useJobForm';
import { useNavigate } from 'react-router-dom';
import { JobProblemsAccordion } from 'components/jobProblems/JobProblemsAccordion';
import { DocumentCheckStatus } from './widgets/JobDetailsWidget/JobDetailsWidget';
import { JobDocumentsWidget } from './widgets/JobDocumentsWidget/JobDocumentsWidget';
import useJobProps from './useJobProps';
import { AppButton, AppButtonType, AppButtonColorScheme } from 'libs/ui/atoms/src/lib/appButton';

export interface JobDefaultModeProps {
  job: Job;
  hideExtraData: boolean;
  jobName: FieldState;
  onJobNameChanged: (name: string) => void;
  isGenerateReportPT: boolean;
  onGenerateReportPTToggle: (isGenerateReportPT: boolean) => void;
  isGenerateReportEN: boolean;
  onGenerateReportENToggle: (isGenerateReportEN: boolean) => void;
  validateSubmittedDocuments: () => Promise<boolean>;
  isLoadingSubmit: boolean;
  isAccordionExpanded: boolean;
  onAccordionExpanded: (isAccordionExpanded: boolean) => void;
  isProblemsNavigationModalToggle: boolean;
  onProblemsNavigationModalToggle: (isProblemsNavigationModalToggle: boolean) => void;
  uploadedDocs: JobDocument[];
  onUploadedDocs: (uploadedDocs: JobDocument[]) => void;
  onDocsSubmit: () => Promise<boolean>;
  checkRequiredDocumentsProvided: (documents: JobDocument[]) => DocumentCheckStatus;
  addDocumentsToPolling: (documents: JobDocument[]) => void;
  documentValidationErrorMessage?: string;
  documentsValidationError: DocumentsValidationErrorTypeEnum | null;
  onDocumentsValidationError: React.Dispatch<React.SetStateAction<DocumentsValidationErrorTypeEnum | null>>;
  onResetDocumentValidationErrorMessage?: () => void;
  isErrorModalShown: boolean;
  setIsErrorModalShown: React.Dispatch<React.SetStateAction<boolean>>;
  certidaoComercialPreFilledAccessCode?: string;
  onResetCertidaoComercialPreFilledAccessCode?: () => void;
}

export function JobDefaultMode({
  job,
  hideExtraData,
  jobName,
  onJobNameChanged,
  isGenerateReportPT,
  onGenerateReportPTToggle,
  isGenerateReportEN,
  onGenerateReportENToggle,
  validateSubmittedDocuments,
  isLoadingSubmit,
  isAccordionExpanded,
  onAccordionExpanded,
  isProblemsNavigationModalToggle,
  onProblemsNavigationModalToggle,
  uploadedDocs,
  onUploadedDocs,
  onDocsSubmit,
  checkRequiredDocumentsProvided,
  addDocumentsToPolling,
  documentValidationErrorMessage,
  documentsValidationError,
  onDocumentsValidationError,
  onResetDocumentValidationErrorMessage,
  isErrorModalShown,
  setIsErrorModalShown,
  certidaoComercialPreFilledAccessCode,
  onResetCertidaoComercialPreFilledAccessCode,
}: JobDefaultModeProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');

  const [isShowUserSelectedUnsupportedTypeModal, onShowUserSelectedUnsupportedTypeModal] = useState(false);

  const { JobMainInfoProps, JobProblemsAccordionProps, JobDocumentsWidgetProps, ReviewSettingsProps } = useJobProps(
    job,
    hideExtraData,
    jobName,
    onJobNameChanged,
    isGenerateReportPT,
    onGenerateReportPTToggle,
    isGenerateReportEN,
    onGenerateReportENToggle,
    uploadedDocs,
    documentsValidationError,
    onUploadedDocs,
    checkRequiredDocumentsProvided,
    onDocumentsValidationError,
    isShowUserSelectedUnsupportedTypeModal,
    onShowUserSelectedUnsupportedTypeModal,
    addDocumentsToPolling,
    isAccordionExpanded,
    onAccordionExpanded,
    isProblemsNavigationModalToggle,
    onProblemsNavigationModalToggle,
    documentValidationErrorMessage,
    onResetDocumentValidationErrorMessage,
    isErrorModalShown,
    setIsErrorModalShown,
    certidaoComercialPreFilledAccessCode,
    onResetCertidaoComercialPreFilledAccessCode,
  );

  const handleDocsSubmit = async () => {
    validateSubmittedDocuments();
    onDocsSubmit();
  };

  return (
    <Flex direction="column" justify="space-between" bg={widgetBackgroundColor}>
      <JobMainInfoWidget {...JobMainInfoProps} />
      <JobProblemsAccordion {...JobProblemsAccordionProps} />

      <Flex padding="20px">
        <JobDocumentsWidget {...JobDocumentsWidgetProps} />
      </Flex>

      <Flex direction="row" justify="space-between" align="center">
        <ReviewSettings {...ReviewSettingsProps} />

        <Flex gap="24px" p="20px" direction={{ base: 'column', md: 'row' }}>
          <AppButton
            buttonType={AppButtonType.PRIMARY_MAIN}
            onClick={() => navigate('/jobs')}
            title={t('pages.details.cancel')}
            colorSchemes={[AppButtonColorScheme.DISABLED]}
          />

          <AppButton
            buttonType={AppButtonType.PRIMARY_MAIN}
            title={t('pages.details.submit')}
            onClick={handleDocsSubmit}
            isLoading={isLoadingSubmit}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
