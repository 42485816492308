import { Flex, Grid, Text, useBreakpointValue, useColorModeValue, AspectRatio } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useTypedSelector } from 'store';
import { helpTicketSelected } from '../../../store/help-tools';
import { HelpCard } from './components/HelpCard';
import { tickets } from './tickets';

import { VeriCasaLogo } from '../../../components/icons/Icons';

export default function HomePage() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const selectedHelpTicketType = useTypedSelector(state => state.helpTools.selectedHelpTicketType);
  const isHelpPanelCollapsed = useTypedSelector(state => state.helpTools.isHelpTicketPanelCollapsed);

  const questionTextColor = useColorModeValue('brand.800', 'gray.200');
  const greetingTextColor = useColorModeValue('blue.900', 'white');
  const logoColor = useColorModeValue('navy.700', 'white');

  const helpCardsColumns = useBreakpointValue({ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' });

  return (
    <Flex
      alignItems={'center'}
      justifyContent="center"
      flex="1"
      height={'100%'}
      flexDir="column"
      gap="24px"
      pb={isHelpPanelCollapsed ? '80px' : '0'}
    >
      <AspectRatio maxH="150px" w="100%" maxW="100%" ratio={4.8}>
        <VeriCasaLogo pl="64px" w="100%" maxW="100%" color={logoColor} />
      </AspectRatio>

      <Text textAlign={'center'} fontWeight={'bold'} fontSize="20px" lineHeight="26px" color={greetingTextColor}>
        {t('increaseYorProductivityWithVeriCasa')}
      </Text>

      <Text textAlign={'center'} fontSize="32px" fontWeight={'bold'} lineHeight="41px" color={questionTextColor}>
        {t('whatDoYouWantToday')}
      </Text>

      <Grid templateColumns={helpCardsColumns} gap="24px">
        {tickets.map((ticket, idx) => (
          <HelpCard
            key={idx}
            ticket={ticket}
            isSelected={selectedHelpTicketType === ticket.type}
            onClick={() => dispatch(helpTicketSelected(ticket.type))}
          />
        ))}
      </Grid>
    </Flex>
  );
}
