import { useColorModeValue, useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Job } from '../../api';
import { AppButton, AppButtonColorScheme, AppButtonType, Copy } from '../../libs/ui/atoms/src';
import { SupportedTranslations, useDynamicTranslation } from './useDynamicTranslation';
import { useFormattedClipboard } from './useFormattedClipboard';

export interface CopyContractsToolProps {
  job: Job;
}

export const CopyContractsTool = ({ job }: CopyContractsToolProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  const textColor = useColorModeValue('blue.800', 'blue.800');
  const menuItemBg = useColorModeValue('yellow.50', '_gray.400');
  const menuItemHoverBg = useColorModeValue('yellow.100', '_gray.300');
  const borderColor = useColorModeValue('_gray.300', '_gray.300');

  const copyToClipboard = useFormattedClipboard();
  const { dynamicTranslate } = useDynamicTranslation();

  const handleCopy = useCallback(
    (text: string) => {
      const copyResult = copyToClipboard({ text });

      if (copyResult) {
        toast({ title: t('copiedToClipboard'), status: 'info', duration: 1500 });
      } else {
        toast({ title: t('copiedToClipboardError'), status: 'error', duration: 1500 });
      }
    },
    [copyToClipboard, t, toast],
  );

  return (
    <>
      {job.contractTexts?.map((text, idx) => (
        <AppButton
          key={`copy-contracts-${idx}`}
          buttonType={AppButtonType.PRIMARY}
          colorSchemes={[AppButtonColorScheme.TRANSPARENT]}
          bg={menuItemBg}
          leftIcon={<Copy boxSize={5} color={textColor} />}
          borderColor={borderColor}
          onClick={() => {
            handleCopy(
              dynamicTranslate({
                [SupportedTranslations.EN]: text.contractTextEn,
                [SupportedTranslations.PT]: text.contractTextPt,
              }),
            );
          }}
          color={textColor}
          _hover={{ bg: menuItemHoverBg }}
        >
          {`${t('clickToCopy')} ${dynamicTranslate({
            [SupportedTranslations.EN]: text.labelEn,
            [SupportedTranslations.PT]: text.labelPt,
          })}`}
        </AppButton>
      ))}
    </>
  );
};
