import { Flex, Input, InputGroup, InputLeftElement, InputRightElement, useColorModeValue } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cancel, Magnifier } from '../icons/icons';

export interface VCSearchProps {
  search: string;
  onSearch: (value: string) => void;
  isFlexible?: boolean;

  isFullWidth?: boolean;

  onSearchFocusChange?: (value: boolean) => void;
}

export const VCSearch = (props: VCSearchProps) => {
  const { search, onSearch, isFlexible, isFullWidth, onSearchFocusChange } = props;

  const { t } = useTranslation();

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const handleFocusChange = (value: boolean) => {
    setIsSearchFocused(value);
    onSearchFocusChange?.(value);
  };

  const textColor = useColorModeValue('brand.800', 'white');
  const bgColor = useColorModeValue('white', 'navy.800');

  return (
    <Flex
      flex={isSearchFocused || isFullWidth ? 1 : undefined}
      justifyContent={'flex-end'}
      onClick={e => e.stopPropagation()}
    >
      <InputGroup borderRadius={'10px'} bg={bgColor}>
        <InputLeftElement pointerEvents="none">
          <Magnifier color={textColor} />
        </InputLeftElement>
        <Input
          w={isSearchFocused || search || isFullWidth ? '100%' : '130px'}
          borderRadius={'10px'}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
          placeholder={t('search')}
          color={textColor}
          borderColor={search !== '' ? textColor : undefined}
          value={search}
          onFocus={() => handleFocusChange(true)}
          onBlur={() => handleFocusChange(false)}
          onChange={e => onSearch(e.target.value)}
        />
        {search && (
          <InputRightElement cursor={'pointer'} onClick={() => onSearch('')}>
            <Cancel color={textColor} w="12px" h="12px" />
          </InputRightElement>
        )}
      </InputGroup>
    </Flex>
  );
};
