import { useMediaQuery, useQuery, useTheme } from '@chakra-ui/react';
import { Dict, get } from '@chakra-ui/utils';
import { APP_BREAKPOINTS } from '../foundations/breakpoints';

const getBreakpoint = (theme: Dict, value: any) => get(theme, `breakpoints.${value}`, value);

export const useAppBreakpoint = (breakpoint: keyof typeof APP_BREAKPOINTS) => {
  const theme = useTheme();
  const bpValue = getBreakpoint(theme, breakpoint);

  const query = useQuery({ above: bpValue });
  const [show] = useMediaQuery(query);

  return show;
};
